import React from 'react';
import { Heading, Icon } from '@fmi/design-system';
import {
  StyledSectionTitle,
  SectionTitleWrapper,
  Hamburger,
} from './StyledSectionTitle';

type Props = {
  title: string;
  onHamburgerClick?: () => void;
};

export const SectionTitle = ({ title, onHamburgerClick }: Props) => {
  return (
    <StyledSectionTitle>
      <SectionTitleWrapper>
        <Hamburger onClick={onHamburgerClick}>
          <Icon icon="MdMenu" size="22px" />
        </Hamburger>
        <Heading level={3} color="white">
          {title}
        </Heading>
      </SectionTitleWrapper>
    </StyledSectionTitle>
  );
};
