import getConfig from 'next/config';
import React, { useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import {
  useBreakpoint,
  useFormData,
  useNavigation,
  useProgress,
} from '@src/hooks';
import { FormDataContext, UserContext, QuoteContext } from '@src/contexts';
import {
  displayValidationRequired,
  displayValidateEmailAddress,
} from '@src/validators';
import {
  removeUndefined,
  lifeInsuredSubmitApplication,
  fetchAdviserIdentity,
} from '@src/utils';
import { Icon, Modal, Heading, TextInput, Text } from '@fmi/design-system';
import { Center, Form, SendToAdviserModal, ModalLoader } from '@src/components';
import { LISubmitApplication } from '@src/models';
import {
  StyledHeader,
  Logo,
  CTAWrapper,
  LiveHelp,
  LiveHelpIconButton,
  SendToClient,
  SendButton,
  CancelButton,
  StyledContent,
  NotesWrapper,
  ModalWrapper,
  SubmitApplication,
  CenteredText,
} from './StyledHeader';

const validateLiEmail = val => {
  const errors = {
    emailAddress:
      displayValidationRequired(
        val,
        'Email Address',
        'Please enter a valid email address'
      ) ||
      displayValidateEmailAddress(
        val,
        'Email Address',
        'Please enter a valid email address'
      ),
  };
  return removeUndefined(errors);
};

export const Header = () => {
  const [fcLoaded, setfcLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendToClientModalIsOpen, setSendToClientModalIsOpen] = useState(false);
  const breakpoint = useBreakpoint();
  const { formData } = useContext(FormDataContext);
  const { user, setUser } = useContext(UserContext);
  const { quote } = useContext(QuoteContext);
  const { userProgress, isAppComplete } = useProgress();
  const { updateFormData } = useFormData();
  const router = useRouter();
  const { emailAddress } = formData.lifeInsuredsDetails;
  const isDisabled = Boolean(emailAddress);
  const { publicRuntimeConfig } = getConfig();
  const applicationdId = router.query.id;
  const { navigate } = useNavigation();
  const complete: boolean =
    userProgress.findIndex(filter => filter.isComplete === false) < 0;

  useEffect(() => {
    const interval = setInterval(() => {
      const global = window as any;
      if (global !== undefined && global.fcWidget !== undefined) {
        setfcLoaded(true);
        clearInterval(interval);
      }
    }, 200);
  }, []);

  const handleSendToClient = async (email, message, applicationId) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        applicationId,
        message,
        email,
      }),
    };

    const result = await fetch(
      `${publicRuntimeConfig.host}/api/createPasswordLessUser`,
      requestOptions
    );
    const json = await result.json();

    console.log(email, message);
  };

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailAddress,
      message: '',
    },
    validate: values => {
      const c = validateLiEmail(values.emailAddress);
      return c;
    },
    onSubmit: async values => {
      setLoading(true);
      // only update LI email and Context if it was not previously populated

      await updateFormData({
        ...formData,
        lifeInsuredsDetails: {
          ...formData.lifeInsuredsDetails,
          emailAddress: values.emailAddress,
        },
      });

      // // now do the send
      await handleSendToClient(
        values.emailAddress,
        values.message,
        applicationdId
      );

      // reset modal and loading
      setSendSuccess(true);
      setLoading(false);
    },
  });

  return (
    <>
      <StyledHeader>
        <Logo src="/images/logo-dark.svg" />
        <CTAWrapper>
          {publicRuntimeConfig.enableSendToClient && !user.isLifeInsured && (
            <SendToClient
              onClick={() => setSendToClientModalIsOpen(true)}
              appearance="red"
              icon="MdPersonPin"
              visible>
              Send To Client
            </SendToClient>
          )}

          {user.isLifeInsured && (
            <SubmitApplication
              onClick={async () => {
                if (complete) setUser({ ...user, reviewModalOpen: true });
                else await navigate('validation-overview', true);
              }}
              appearance="red"
              icon="MdPlaylistAddCheck"
              visible>
              Send To Adviser
            </SubmitApplication>
          )}

          {breakpoint !== 'mobile' && (
            <LiveHelp
              onClick={() => (window as any).fcWidget.open()}
              appearance="charcoal"
              icon="MdChat"
              visible={fcLoaded}>
              Live Help
            </LiveHelp>
          )}

          {breakpoint === 'mobile' && (
            <LiveHelpIconButton
              onClick={() => (window as any).fcWidget.open()}
              visible={fcLoaded}>
              <Icon icon="MdChat" size="22px" color="charcoal" />
            </LiveHelpIconButton>
          )}
        </CTAWrapper>
      </StyledHeader>

      <Modal isOpen={sendToClientModalIsOpen} width={700}>
        {!loading && !sendSuccess ? (
          <ModalWrapper>
            <CenteredText>
              <Heading level={3}>Send Application to Client</Heading>
              {isAppComplete && (
                <Text>
                  This completed application will be sent to the client for
                  review. It has not been submitted yet.
                </Text>
              )}
              {!isAppComplete && (
                <Text>
                  This partially completed application will be sent to the
                  client to complete.
                </Text>
              )}
            </CenteredText>
            <StyledContent>
              <Form onSubmit={formik.handleSubmit} id="email-client-form">
                <TextInput
                  id="emailAddress"
                  label="Email Address of the Client"
                  value={emailAddress}
                  onChange={formik.handleChange}
                  fieldType="email"
                  error={
                    !!(
                      formik.touched.emailAddress && formik.errors.emailAddress
                    )
                  }
                  errorMessage={formik.errors.emailAddress}
                  disabled={isDisabled}
                />
                <NotesWrapper>
                  <TextInput
                    id="message"
                    label="Add a Personalised Note (Optional)"
                    multiline
                    onChange={formik.handleChange}
                  />
                </NotesWrapper>
              </Form>
            </StyledContent>
            <Center>
              <SendButton
                appearance="red"
                onClick={formik.submitForm}
                loading={loading}>
                Send To Client
              </SendButton>
              <CancelButton
                appearance="charcoal"
                disabled={loading}
                onClick={() => setSendToClientModalIsOpen(false)}>
                Cancel
              </CancelButton>
            </Center>
          </ModalWrapper>
        ) : (
          <ModalLoader
            loading={loading}
            loadingText="Sending application..."
            successText="Application successfully sent to client."
            onDismiss={() => setSendToClientModalIsOpen(false)}
          />
        )}
      </Modal>

      <SendToAdviserModal
        isOpen={user.reviewModalOpen}
        onReviewAnswers={() => {
          navigate('life-insureds-details', true).then(() =>
            setUser({ ...user, reviewModalOpen: false })
          );
        }}
        onSendToAdviser={async () => {
          const complete: boolean =
            userProgress.findIndex(filter => filter.isComplete === false) < 0;

          console.log('SendToAdviserModal fethcing adviser identity.');
          const adviser = await fetchAdviserIdentity(
            quote.ifa.mavericPK.toString()
          );
          console.log('SendToAdviserModal fetched adviser identity.');

          console.log(
            `SendToAdviserModal creating adviser email with details ${publicRuntimeConfig.host}/app/${applicationdId}/life-insureds-details`
          );

          const getAdviserBd = () => {
            if (adviser.bdEmail) {
              return [
                {
                  email: adviser.bdEmail,
                  name: `${adviser.bdFirstName} ${adviser.bdLastName}`,
                },
              ];
            }

            return [];
          };

          const sendEmail: LISubmitApplication = {
            categories: [],
            template:
              complete === true
                ? 'LifeInsuredSubmitApplicationComplete'
                : 'LifeInsuredSubmitApplicationIncomplete',
            templateData: {
              FinancialAdviserName: `${adviser.firstName} ${adviser.lastName}`,
              LifeInsuredName: `${formData?.lifeInsuredsDetails?.firstName} ${formData?.lifeInsuredsDetails?.surname}`,
              QuoteNumber: quote?.quoteDetail?.quoteNumber,
              ApplicationLink: `${publicRuntimeConfig.host}/app/${applicationdId}/life-insureds-details`,
            },
            toEmailAddressList: [
              {
                email: adviser.email,
                name: `${adviser.firstName} ${adviser.lastName}`,
              },
            ],
            ToBccEmailAddressList: getAdviserBd(),
          };
          console.log('SendToAdviserModal created adviser email with details.');
          await lifeInsuredSubmitApplication(sendEmail);
          console.log('SendToAdviserModal sent email to adviser.');
        }}
      />
    </>
  );
};
