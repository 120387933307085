import styled from 'styled-components';
import { Text } from '@fmi/design-system';

const StyledFooter = styled.footer<{
  positionAbsolutely: boolean;
}>`
  background-color: ${props => props.theme.colors.lightGrey};
  padding: ${props => props.theme.gutters[40]}
    ${props => props.theme.gutters[16]};

  height: 207px;
  flex-shrink: 0;
  width: 100%;

  @media ${props => props.theme.BREAKPOINTS.TABLET} {
    height: 187px;
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    position: relative;
    height: 194px;
    margin-top: ${props => props.theme.gutters[24]};
    padding: ${props => props.theme.gutters[24]}
      ${props => props.theme.gutters[32]} ${props => props.theme.gutters[40]};

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background: ${props => props.theme.colors.grey};
      position: absolute;
      top: -1px;
      left: 0;
      opacity: 0.5;
    }
  }
`;

const InnerFooter = styled.div`
  text-align: center;
`;

const FooterLinkHeader = styled(Text)`
  font-weight: bold;
  color: ${props => props.theme.colors.charcoal};
  margin: 0 0 9px 0;
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: ${props => props.theme.gutters[16]};
`;

const FooterLinkItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Bullet = styled.div`
  color: #c8c8c8;
  font-size: 22px;
  margin-left: 12px;
  margin-right: 12px;
`;

const IconContainer = styled.div`
  color: ${props => props.theme.colors.charcoal};
  padding-top: 3px;
  margin-right: 4px;
`;

const LinkText = styled(Text)`
  margin: 0;
`;

const EmailText = styled.a`
  margin: 0;
  color: ${props => props.theme.colors.charcoal};
  font-size: 0.875rem;
  line-height: 1.313em;
  font-weight: 400;
  text-decoration: underline;
`;

const StyledCopyrightText = styled(Text)`
  margin: 0;
  line-height: 1.62;
  color: ${props => props.theme.colors.darkGrey};
  font-size: ${props => props.theme.fontSize[13]};

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    font-size: ${props => props.theme.fontSize[14]};
  }
`;

export {
  StyledFooter,
  InnerFooter,
  FooterLinkHeader,
  FooterLinks,
  FooterLinkItem,
  EmailText,
  IconContainer,
  StyledCopyrightText,
  LinkText,
  Bullet,
};
