import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import {
  Card,
  Heading,
  Text,
  PrimaryButton,
  Loader,
  Modal,
} from '@fmi/design-system';
import { Center, UploadSummaryList, FileSelectModal } from '@src/components';
import { RequiredFile, UploadedFile } from './Models';
import { ActiveUploads } from './ActiveUploads';
import { FileItemsList } from './FileItemsList';

type Props = {
  requiredFiles: RequiredFile[];
  initialFiles: UploadedFile[];
  onFilesAddedOrRemoved: (files: UploadedFile[]) => void;
};

// ABANDON ALL HOPE YE WHO ENTER HERE!!!

// Function component
export const MultiFileUpload: React.FC<Props> = ({
  requiredFiles,
  initialFiles,
  onFilesAddedOrRemoved,
}) => {
  // Modal Controls
  const [isNoDocumentModalOpen, setIsNoDocumentModalOpen] = useState(false);
  const [isFileSelectModalOpen, setIsFileSelectModalOpen] = useState(false);

  // Activity State Controls
  const [isBusy, setIsBusy] = useState(false);

  // Uploaded Files State
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [activeUpload, setActiveUpload] = useState<UploadedFile>(null);
  const [uploadQueue, setUploadQueue] = useState<UploadedFile[]>([]);
  const [fileSelectOptions, setFileSelectOptions] = useState([]);
  const [disableUpload, setDisableUpload] = useState(false);

  const shouldDisableUpload = () => {
    const filteredQueue = uploadQueue.filter(q => q.status !== 'error');
    const dis = requiredFiles.some(f => f.allowMultiple)
      ? false
      : filteredQueue.length > 0 ||
        filteredQueue.length === requiredFiles.length;
    setDisableUpload(dis);
  };

  const updateFilesAvailableToUpload = () => {
    const joined =
      uploadQueue.length > 0
        ? [...uploadedFiles, ...uploadQueue]
        : uploadedFiles;
    const allowed = requiredFiles.filter(x => {
      return !joined.some(
        n => n.requiredFile === x.name && n.status !== 'error'
      );
    });
    setFileSelectOptions(allowed);
  };

  const getUploadSummary = () => {
    const sl = [];
    requiredFiles.forEach(rf => {
      const count = uploadedFiles.filter(
        u => u.requiredFile === rf.name && u.status === 'success'
      ).length;
      const hasError = uploadedFiles.some(
        u => u.requiredFile === rf.name && u.status === 'error'
      );
      sl.push({
        name: rf.name,
        allowMultiple: rf.allowMultiple,
        uploadedCount: count,
        hasError,
      });
    });

    return sl;
  };

  useEffect(() => {
    setUploadedFiles([...uploadedFiles, ...initialFiles]);
  }, []);

  // Effect fires after file upload complete
  useEffect(() => {
    updateFilesAvailableToUpload();
    shouldDisableUpload();
    // call back to parent form with changes
    onFilesAddedOrRemoved(uploadedFiles);
  }, [uploadedFiles]);

  // while file busy uploading...
  useEffect(() => {
    updateFilesAvailableToUpload();
    shouldDisableUpload();
  }, [activeUpload]);

  // METHODS

  // Handle file selected from FileSelectModal
  const handleOnFileSelected = useCallback(upload => {
    setIsFileSelectModalOpen(false);
    setActiveUpload(upload);
    setUploadQueue([...uploadQueue, upload]);
  }, []);

  const handleOnFileRemoved = timestamp => {
    setUploadedFiles(uploadedFiles.filter(x => x.timestamp !== timestamp));
  };

  const handleFileUploaded = (newFile: UploadedFile) => {
    setUploadedFiles([...uploadedFiles, newFile]);
    setUploadQueue(uploadQueue.filter(x => x.timestamp !== newFile.timestamp));
    setActiveUpload(null);
  };

  const handleUploadCancelled = timestamp => {
    setUploadQueue(uploadQueue.filter(x => x.timestamp !== timestamp));
    setActiveUpload(null);
  };

  return (
    <>
      <Card>
        <Center>
          <TitleText appearance="misc">Tap To Upload</TitleText>
          <InstructionText color="charcoal">
            Please upload the following documents:
          </InstructionText>
          <StyledDocsListContainer>
            <UploadSummaryList fileList={getUploadSummary()} />
          </StyledDocsListContainer>

          {isBusy && <BusyLoader size="30px" color="red" />}

          <UploadButton
            appearance="blue"
            onClick={e => {
              e.preventDefault();
              setIsFileSelectModalOpen(true);
            }}
            disabled={disableUpload}>
            {uploadedFiles.length > 0 ? '+ Add Another' : 'Start Uploading'}
          </UploadButton>

          {/* Current Uploads */}
          <ActiveUploads
            fileToUpload={activeUpload}
            onFileUploaded={newFile => handleFileUploaded(newFile)}
            onUploadCancelled={handleUploadCancelled}
          />

          {/* Failed Uploads */}
          <FileItemsList
            items={uploadedFiles.filter(f => f.status === 'error')}
            onDelete={f => handleOnFileRemoved(f)}
          />

          {/* Successful Uploads */}
          {uploadedFiles.filter(f => f.status === 'success').length > 0 && (
            <>
              <SuccessFullTitleText appearance="misc">
                Successfully Upload Files
              </SuccessFullTitleText>
              <FileItemsList
                items={uploadedFiles.filter(f => f.status === 'success')}
                onDelete={f => handleOnFileRemoved(f)}
              />
            </>
          )}

          <StyledLink
            color="charcoal"
            onClick={() => setIsNoDocumentModalOpen(true)}>
            What if I can&apos;t upload now?
          </StyledLink>
        </Center>
      </Card>

      {/* FILE SELECT MODAL */}
      {/* selects the file to upload and calls back here to initiate the upload */}
      <FileSelectModal
        isOpen={isFileSelectModalOpen}
        filesList={fileSelectOptions}
        onFileSelected={upload => handleOnFileSelected(upload)}
        onModalDismiss={() => setIsFileSelectModalOpen(false)}
      />

      {/* DONT HAVE DOCUMENT MODAL */}
      <Modal isOpen={isNoDocumentModalOpen}>
        <StyledModalContent>
          <Heading level={3}>Can&apos;t upload right now?</Heading>
          <Text color="charcoal">
            Don&apos;t worry. You can complete the application and we'll be in
            touch to collect the necessary documentation.
          </Text>
          <PrimaryButton onClick={() => setIsNoDocumentModalOpen(false)}>
            Got It, Thanks!
          </PrimaryButton>
        </StyledModalContent>
      </Modal>
    </>
  );
};

const TitleText = styled(Text)`
  margin: 0;
  text-align: center;
  font-size: ${props => props.theme.fontSize[13]};
`;

const InstructionText = styled(Text)`
  margin: 0;
  margin-top: ${props => props.theme.gutters[4]};
  text-align: center;
`;

const StyledDocsListContainer = styled.div`
  padding: 16px;
`;

const BusyLoader = styled(Loader)`
  margin: 0;
  margin-top: ${props => props.theme.gutters[32]};
  margin-bottom: ${props => props.theme.gutters[40]};
`;

const UploadButton = styled(PrimaryButton)`
  margin: 0;
  margin-top: ${props => props.theme.gutters[24]};
  margin-bottom: ${props => props.theme.gutters[24]};
`;

const StyledLink = styled(Text)`
  margin: 0;
  margin-top: ${props => props.theme.gutters[32]};
  text-decoration: underline;
  cursor: pointer;
`;

const ErrorText = styled(Text)`
  text-align: center;
`;

const SuccessFullTitleText = styled(Text)`
  text-align: center;
  font-size: ${props => props.theme.fontSize[13]};
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
