import getConfig from 'next/config';
import { Nationality, SelectListGroup } from '@src/models';

export const fetchNationalities = async (): Promise<SelectListGroup[]> => {
  const { publicRuntimeConfig } = getConfig();
  const result = await fetch(`${publicRuntimeConfig.host}/api/nationalities`);
  const json = (await result.json()) as Nationality[];

  const top = json
    .filter(x => x.displayOrder > 0)
    .sort((a, b) => {
      if (a.displayOrder < b.displayOrder) {
        return -1;
      }
      if (a.displayOrder > b.displayOrder) {
        return 1;
      }
      return 0;
    })
    .map(item => ({
      label: item.nationalityName,
      value: item.nationalityName,
    }));

  const rest = json
    .filter(x => x.displayOrder === 0)
    .map(item => ({
      label: item.nationalityName,
      value: item.nationalityName,
    }));

  const groupedOptions = [
    {
      label: 'Popular Nationalities',
      options: top,
    },
    {
      label: 'Other Nationalities',
      options: rest,
    },
  ];

  return groupedOptions;
};
