import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0
  }

  to {
    opacity: 0.8
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.colors.charcoal};
  opacity: 0.8;
  z-index: 100;
  animation: ${fadeIn} 0.1s ease;
`;

const StyledWidget = styled.div<{
  isOpen: boolean;
}>`
  width: 100%;
  height: 100%;
  display: ${props => (props.isOpen ? 'block' : 'none')};
`;

const slideIn = keyframes`
  from {
    transform: translate(500px, 0)
  }

  to {
    transform:translate(0, 0)
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: white;
  height: 100%;
  z-index: 100;
  animation: ${slideIn} 0.3s ease;

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    max-width: 499px;
    padding-top: 99px;
  }
`;

const StyledTitleBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  position: fixed;
  background: ${props => props.theme.colors.charcoal};
  width: 100%;
  z-index: 1;

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    max-width: 499px;
    height: 78px;
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    height: 102px;
  }
`;

const StyledScrollContainer = styled.div`
  padding-top: 80px;
  width: 100%;
  height: 100%;

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    padding-top: 78px;
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    padding-top: 102px;
  }
`;

const StyledIconWrapper = styled.div`
  color: ${props => props.theme.colors.sinbad};
  cursor: pointer;
  position: absolute;
  left: ${props => props.theme.gutters[16]};

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    left: ${props => props.theme.gutters[32]};
  }
`;

const StyledContent = styled.div`
  background-color: white;
  padding-left: ${props => props.theme.gutters[16]};
  padding-right: ${props => props.theme.gutters[16]};
  padding-bottom: ${props => props.theme.gutters[40]};
  z-index: 99999;
  padding-top: ${props => props.theme.gutters[24]};

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    padding-left: ${props => props.theme.gutters[32]};
    padding-right: ${props => props.theme.gutters[32]};
    padding-bottom: ${props => props.theme.gutters[32]};
  }

  form {
    padding-top: 0;
  }
`;

const StyledSnlActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: ${props => props.theme.gutters[32]};
  padding-right: ${props => props.theme.gutters[32]};
  padding-bottom: ${props => props.theme.gutters[32]};
  background: white;

  @media ${props => props.theme.BREAKPOINTS.MOBILE_DOWN} {
    flex-direction: column-reverse;
  }

  .button {
    margin-top: 0;
    margin-bottom: ${props => props.theme.gutters[16]};
  }
`;

export {
  Overlay,
  StyledWidget,
  StyledContainer,
  StyledTitleBar,
  StyledScrollContainer,
  StyledIconWrapper,
  StyledContent,
  StyledSnlActions,
};
