import React from 'react';
import styled from 'styled-components';

const DashedPanel = props => {
  const { children, padding, ...rest } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledDashedPanel padding={padding} {...rest}>
      {children}
    </StyledDashedPanel>
  );
};

const StyledDashedPanel = styled.div<{ padding }>`
  border-radius: 0.5em;
  border: 0.063em dashed ${props => props.theme.colors.grey};
  background-color: ${props => props.theme.colors.white};
  padding: ${props =>
    props.padding ? props.padding : props.theme.gutters[16]};
  text-align: center;
`;

export { DashedPanel };
