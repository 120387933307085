import { UploadedFileItem } from '@src/models/FormData';
import { RequiredFile, UploadedFile } from '@src/components/FileUpload/Models';

export const mapFiles = (files: UploadedFileItem[]): UploadedFile[] => {
  if (!files) return [];

  return files.map(item => ({
    requiredFile: item.requiredFile,
    uploadedFileName: item.uploadedFileName,
    description: item.description,
    timestamp: item.timestamp,
    size: item.size,
    type: item.type,
    progress: 100,
    fullUri: item.fullUri,
    status: 'success',
    uploadedSize: item.size,
    fileData: null,
    cancelToken: null,
  }));
};

export const mapUploads = (files: UploadedFile[]) => {
  return files.map(
    item =>
      ({
        timestamp: item.timestamp,
        requiredFile: item.requiredFile,
        uploadedFileName: item.uploadedFileName,
        description: item.description,
        size: item.size,
        type: item.type,
        fullUri: item.fullUri,
      } as UploadedFileItem)
  );
};

export const updateFileItemInArray = (sourceArray, newItem) => {
  const foundItem = sourceArray.find(x => x.timestamp === newItem.timestamp);

  // bail if items isnt in the source (or add it? Hmmm)
  if (!foundItem) return sourceArray;

  // return a new array containing the modified item: yippee
  const newArr = sourceArray.map(item => {
    return item.timestamp === newItem.timestamp
      ? (newItem as UploadedFile)
      : (item as UploadedFile);
  });
  return newArr;
};
