export enum AuraSections {
  FinancialStatus = 0,
  Travel = 1,
  OtherLongTermInsurance = 2,
  HobbiesAndPursuits = 3,
  FamilyHistory = 4,
  MedicalDetails = 5,
  MedicalAssessment = 6,
  FinancialData = 7,
  Occupation = 8,
}

// The above is a UI index, below is the actual sectionId
// AuraSectionIDs[AuraSections[currentSection]] used to lookup Id from Index
export enum AuraSectionIDs {
  FinancialStatus = 0,
  Travel = 1,
  OtherLongTermInsurance = 2,
  HobbiesAndPursuits = 3,
  FamilyHistory = 4,
  MedicalDetails = 5,
  MedicalAssessment = 6,
  FinancialData = 7,
  Occupation = 8,
}
