import React from 'react';
import styled from 'styled-components';
import { Loader, Text, Icon } from '@fmi/design-system';

type Props = {
  loading: boolean;
  loadingText: string;
  successText: string;
  onDismiss?: () => void;
};

export const ModalLoader: React.FC<Props> = props => {
  const { loading, loadingText, successText, onDismiss } = props;
  return (
    <StyledModalLoader>
      {loading ? (
        <>
          <Loader />
          <ModalText>{loadingText}</ModalText>
        </>
      ) : (
        <>
          <IconContainer>
            <Icon size={48} color="#78aa3c" icon="MdCheckCircle" />
          </IconContainer>
          <ModalText>{successText}</ModalText>
          <DismissButton onClick={onDismiss}>Dismiss</DismissButton>
        </>
      )}
    </StyledModalLoader>
  );
};

const StyledModalLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ModalText = styled(Text)`
  color: ${props => props.theme.colors.black};
  margin: ${props => props.theme.gutters[12]} 0 0 0;
`;

const IconContainer = styled.div`
  width: 48px;
  height: 48px;
`;

const DismissButton = styled.button`
  background: none;
  color: ${props => props.theme.colors.blue};
  font-size: 13px;
  margin-top: ${props => props.theme.gutters[16]};
  font-weight: bold;
  line-height: 20px;
  text-transform: uppercase;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
`;
