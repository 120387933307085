import React from 'react';
import { Icon } from '@fmi/design-system';
import {
  StyledFooter,
  InnerFooter,
  StyledCopyrightText,
  FooterLinks,
  FooterLinkItem,
  IconContainer,
  LinkText,
  EmailText,
  Bullet,
  FooterLinkHeader,
} from './StyledFooter';

type Props = {
  positionAbsolutely?: boolean;
};

const Footer: React.FC<Props> = ({ positionAbsolutely = true }) => {
  return (
    <StyledFooter positionAbsolutely={positionAbsolutely}>
      <InnerFooter>
        <FooterLinkHeader appearance="small">
          Bidvest Life Customer Service
        </FooterLinkHeader>
        <FooterLinks>
          <FooterLinkItem>
            <IconContainer>
              <Icon icon="MdPhone" size="18px" />
            </IconContainer>
            <LinkText color="charcoal" appearance="small">
              086 010 1119
            </LinkText>
          </FooterLinkItem>
          <FooterLinkItem>
            <Bullet>•</Bullet>
          </FooterLinkItem>
          <FooterLinkItem>
            <IconContainer>
              <Icon icon="MdEmail" size="18px" />
            </IconContainer>
            <EmailText href="mailto:clientcare@bidvestlife.co.za">
              clientcare@bidvestlife.co.za
            </EmailText>
          </FooterLinkItem>
        </FooterLinks>
        <StyledCopyrightText>
          All rights reserved. © {new Date().getFullYear()} BidvestLife.co.za.
          <br />A licensed Life Insurance company and authorised Financial
          Services Provider FSP 47801.
        </StyledCopyrightText>
      </InnerFooter>
    </StyledFooter>
  );
};

export { Footer };
