import React, { useState, useEffect } from 'react';
import { Text, TextInput } from '@fmi/design-system';
import styled from 'styled-components';
import { AuraQuestion } from '@src/models';

type BloodPressureProps = {
  question: AuraQuestion;
  onAnswer: (answer: {
    questionType: string;
    unknownAnswer: boolean;
    readings: {
      diastolic: number;
      systolic: number;
    }[];
  }) => void;
  isValidating: boolean;
};

export const BloodPressure: React.FC<BloodPressureProps> = props => {
  const { question, onAnswer, isValidating } = props;
  const [systolicValue, setSystolicValue] = useState(0);
  const [diastolicValue, setDiastolicValue] = useState(0);

  useEffect(() => {
    if (question.isAnswered) {
      setDiastolicValue(question.answer.readings[0].diastolic);
      setSystolicValue(question.answer.readings[0].systolic);
    }
  }, []);

  const getValidation = (isSystolic: boolean): string => {
    if (isSystolic) {
      if (!(systolicValue >= question.constraints.minValSystolic &&
        systolicValue <= question.constraints.maxValSystolic)) {
        return `Please enter a numeric systolic reading between ${question.constraints.minValSystolic} and ${question.constraints.maxValSystolic}`;
      }
    } else {
      if (!(diastolicValue >= question.constraints.minValDiastolic &&
        diastolicValue <= question.constraints.maxValDiastolic)) {
        return `Please enter a numeric diastolic reading between ${question.constraints.minValDiastolic} and ${question.constraints.maxValDiastolic}`;
      }
    }

    if (!(diastolicValue < systolicValue)) {
      return `Systolic reading must be greater than diastolic reading.`;
    }

    return undefined;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, isSystolic: boolean) => {
    let { value, min, max } = e.target;
    const regex = /^[0-9\b]+$/;
    if (value === '' || regex.test(value)) {
      e.target.value = value
    }

    if (value !== '') {
      if (Number(value) < Number(min)) {
        value = min;
        e.target.value = min;
      } else if (Number(value) > Number(max)) {
        value = max;
        e.target.value = max;
      }
    }
  }

  return (
    <div>
      <Text>{question.text}</Text>
      <UnitizedContainer>
        <TextInput
          label={'Diastolic'}
          min={question.constraints.minValDiastolic}
          max={question.constraints.maxValDiastolic}
          fieldType="number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(e, false);
            setDiastolicValue(Number(e.target.value));
          }}
          onBlur={() => {
            if (getValidation(false) === undefined) {
              onAnswer(
                {
                  questionType: question.type,
                  unknownAnswer: false,
                  readings: [{
                    diastolic: diastolicValue,
                    systolic: systolicValue
                  }]
                }
              )
            }
          }}
          value={question.isAnswered && question.answer.readings[0].diastolic}
          error={isValidating && getValidation(false) !== undefined}
          errorMessage={getValidation(false)}
        />
        <TextInput
          label={'Systolic'}
          min={question.constraints.minValSystolic}
          max={question.constraints.maxValSystolic}
          fieldType="number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(e, true);
            setSystolicValue(Number(e.target.value));
          }}
          onBlur={() => {
            if (getValidation(true) === undefined) {
              onAnswer(
                {
                  questionType: question.type,
                  unknownAnswer: false,
                  readings: [{
                    diastolic: diastolicValue,
                    systolic: systolicValue
                  }]
                }
              )
            }
          }}
          value={question.isAnswered && question.answer.readings[0].systolic}
          error={isValidating && getValidation(true) !== undefined}
          errorMessage={getValidation(true)}
        />
      </UnitizedContainer>
    </div>
  );
};

const UnitizedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    width: 49%;
  }
`;
