import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, FormSection, Stack, FormActions, RouteChangeModal } from '@src/components';
import { fetchAuraQuestions, fetchAuraAnswer, fetchAuraSearchResultsForQuestion } from '@src/utils';
import { SegmentedControl, Text, TextInput, RadioButtonGroup, Heading, AsyncSelect } from '@fmi/design-system';
import { AuraQuestion, AuraAnswer, AuraSections, AuraDoctor } from '@src/models';
import { useNavigation, usePreventRouteChangeIf, useSection } from '@src/hooks';
import useAsyncQueue from 'use-async-queue';
import { useRouter } from 'next/router';
import { AddDoctorSnl } from '@src/sections/aura/AddDoctorSnl';
import { Date } from './components/Date';
import { PositionedLoader } from '../PositionedLoader';
import { Doctor } from './components/Doctor';
import { Unitized } from './components/Unitized';
import { BloodPressure } from './components/BloodPressure';
import { CheckboxGroup } from './components/CheckboxGroup';

const AuraRest: React.FC<{
  currentSection: AuraSections;
  groupByContext?: boolean;
}> = ({ currentSection, groupByContext = true }) => {
  const router = useRouter();
  const { navigateToNextSection } = useNavigation();
  const { activeSection, activeSubSection } = useSection();
  const [loading, setLoading] = useState(false);
  const [auraLoading, setAuraLoading] = useState(true);
  const [data, setData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [isValidating, setIsValidating] = useState(false);
  const [isAnswering, setIsAnswering] = useState(false);
  const [routeChangeModalOpen, setRouteChangeModalOpen] = useState(false);
  const [navigatedRoute, setNavigatedRoute] = useState('');

  // generic groupby function to group aura questions
  function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const groupQuestionsByContext = (questions: AuraQuestion[]) => {
    const groupedQuestions = groupBy(questions, 'context');
    const mappedQuestions = Object.keys(groupedQuestions).map(key => {
      return {
        context: key,
        data: groupedQuestions[key],
      };
    });
    return mappedQuestions;
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      const questions = await fetchAuraQuestions(router.query.id as string, currentSection);
      setData(groupByContext ? groupQuestionsByContext(questions) : questions);
      setQuestionsData(questions);
      setAuraLoading(false);
    };

    fetchQuestions();
  }, []);

  const checkForIncompleteQuestions = () => {
    const incomplete = questionsData.filter(x => !x.isAnswered);
    return incomplete.length > 0;
  };

  const checkForIncompleteSubsections = () => {
    if (activeSection.subsections.length > 0) {
      return activeSubSection.isComplete;
    } else {
      return activeSection.isComplete;
    }
  };

  // handle the showing route change modal
  usePreventRouteChangeIf(
    checkForIncompleteQuestions() && checkForIncompleteSubsections() && !routeChangeModalOpen,
    url => {
      setRouteChangeModalOpen(true);
      setNavigatedRoute(url);
    }
  );

  const inflight = task => {
    console.log(`starting ${task.id}`);
  };

  const done = async task => {
    console.log(`finished ${task.id}`);
  };

  const drain = () => {
    console.log('all done');
    setIsAnswering(false);
  };

  const queue = useAsyncQueue({
    concurrency: 1,
    inflight,
    done,
    drain,
  });

  const answerQuestion = (answerBody: AuraAnswer | any, id: number) => {
    const task = {
      id,
      task: () =>
        new Promise(resolve => {
          resolve(
            fetchAuraAnswer(answerBody, id, router.query.id as string, currentSection)
              .then(q => {
                if (q) {
                  console.log('questions returned', q);
                  setData(groupByContext ? groupQuestionsByContext(q) : q);
                  setQuestionsData(q);
                }
              })
              .catch(error => console.error(error))
          );
        }),
    };
    setIsAnswering(true);
    setIsValidating(false);
    queue.add(task);
  };

  const mapAuraComponents = (question: AuraQuestion) => {
    const { choices, componentType } = question.constraints;
    switch (componentType) {
      case 'RadioResp':
      case 'TableResp': {
        // if there are 2 choices and their values are yes / no render segmented control
        if (choices.length === 2 && choices[0].value === 'true' && choices[1].value === 'false') {
          const mappedSegments = choices.map(i => i.text);
          return (
            <div>
              <Text>{question.text}</Text>
              <SegmentedControl
                defaultOption={mappedSegments[0]}
                segments={mappedSegments}
                value={question.isAnswered && question.answer.text}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  answerQuestion(
                    {
                      answer: {
                        questionType: question.type,
                        value: e.target.value === 'Yes',
                        unknownAnswer: false,
                      },
                    },
                    question.id
                  )
                }
                error={isValidating && !question.isAnswered}
                errorMessage="Please make a selection"
              />
            </div>
          );
        }
        // else render radio buttons
        const mappedOptions = choices.map(i => {
          return {
            label: i.text,
            value: i.value,
          };
        });
        return (
          <div>
            <Text>{question.text}</Text>
            <RadioButtonGroup
              options={mappedOptions}
              value={question.isAnswered && question.answer.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                answerQuestion(
                  {
                    answer: {
                      questionType: question.type,
                      value: e.target.value,
                      unknownAnswer: e.target.value === 'ANSWER_IS_UNKNOWN_CODE',
                    },
                  },
                  question.id
                )
              }
              error={isValidating && !question.isAnswered}
              errorMessage="Please make a selection"
            />
          </div>
        );
      }
      case 'CheckBoxResp':
        return (
          <CheckboxGroup
            question={question}
            error={isValidating && !question.isAnswered}
            onAnswer={answer => {
              answerQuestion(
                {
                  answer,
                },
                question.id
              );
            }}
          />
        );
      case 'RepeatResp':
        return (
          <>
            <IndentedHeading>
              <IndentedHeadingHeading level={4} weight={500}>
                {question.context}
              </IndentedHeadingHeading>
              <IndentedHeadingSubHeading>Please provide the following information</IndentedHeadingSubHeading>
            </IndentedHeading>
          </>
        );
      // if no component is found switch through the question type
      default:
        switch (question.type) {
          case 'NUMERIC':
            return (
              <>
                <TextInput
                  label={question.text}
                  fieldType="number"
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                    answerQuestion(
                      {
                        answer: {
                          questionType: question.type,
                          value: e.target.value,
                        },
                      },
                      question.id
                    )
                  }
                  value={question.isAnswered && question.answer.value}
                  error={isValidating && !question.isAnswered}
                  errorMessage="Please enter a value"
                />
              </>
            );
          case 'DATE':
            return (
              <Date
                question={question}
                error={isValidating && !question.isAnswered}
                onAnswer={answer => {
                  answerQuestion(
                    {
                      answer,
                    },
                    question.id
                  );
                }}
              />
            );
          case 'STATEMENT':
            return (
              <div>
                <Heading level={4}>{question.text}</Heading>
              </div>
            );
          case 'UNITIZED': {
            return (
              <Unitized
                question={question}
                isValidating={isValidating}
                onAnswer={answer => {
                  answerQuestion(
                    {
                      answer,
                    },
                    question.id
                  );
                }}
              />
            );
          }
          case 'SEARCH': {
            const mappedChoices = async inputValue => {
              if (inputValue.length > 1) {
                const { constraints } = await fetchAuraSearchResultsForQuestion(
                  router.query.id as string,
                  question.id,
                  inputValue
                );
                return constraints.choices.map(choice => {
                  return {
                    label: choice.text,
                    value: choice.value,
                  };
                });
              }

              return [];
            };

            const handleChange = ({ value }) => {
              answerQuestion(
                {
                  answer: {
                    questionType: question.type,
                    answers: value
                      ? value.map(v => {
                        return {
                          text: v.label,
                          value: v.value,
                        };
                      })
                      : undefined,
                  },
                },
                question.id
              );
            };

            return (
              <>
                <Text>{question.text}</Text>
                <AsyncSelect
                  placeholder="Type to Search"
                  options={mappedChoices}
                  name="asyncSelect"
                  isMulti
                  showSearchIcon
                  onChange={handleChange}
                  value={
                    question.isAnswered &&
                    question.answer.answers.map(a => {
                      return {
                        label: a.text,
                        value: a.value,
                      };
                    })
                  }
                  error={isValidating && !question.isAnswered}
                  errorMessage="Please enter a value"
                />
              </>
            );
          }

          case 'TEXT':
            return (
              <>
                <Text>{Array.from(question.context).join(' / ')}</Text>
                <TextInput
                  label={question.text}
                  multiline
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                    answerQuestion(
                      {
                        answer: {
                          questionType: question.type,
                          value: e.target.value,
                        },
                      },
                      question.id
                    )
                  }
                  value={question.isAnswered && question.answer.value}
                  error={isValidating && !question.isAnswered}
                  errorMessage="Please enter a value"
                />
              </>
            );
          case 'DOCTOR':
            return (
              <Doctor
                question={question}
                error={isValidating && !question.isAnswered}
                onAnswer={answer =>
                  answerQuestion(
                    {
                      answer,
                    },
                    question.id
                  )
                }
              />
            );
          case 'BLOOD_PRESSURE':
            return (
              <BloodPressure
                question={question}
                isValidating={true}// TODO: CHECK Why validations ({isValidating}) are set to false until sumbit.
                onAnswer={answer => {
                  answerQuestion(
                    {
                      answer,
                    },
                    question.id
                  );
                }}
              />
            );
          case 'NOT_IMPLEMENTED':
            return null;
          default:
            return (
              <Text>
                No component found for <strong>componentType: {componentType || 'null'}</strong> or{' '}
                <strong>questionType: {question.type}</strong>
              </Text>
            );
        }
    }
  };

  if (auraLoading) return <PositionedLoader />;

  const mapContexts = () => {
    if (!groupByContext) {
      return data.map(auraQuestion => mapAuraComponents(auraQuestion));
    }

    return data.map(d => {
      return (
        <>
          {d.context && (
            <IndentedHeading>
              <IndentedHeadingHeading level={4} weight={500}>
                {d.context}
              </IndentedHeadingHeading>
              <IndentedHeadingSubHeading>Please provide the following information</IndentedHeadingSubHeading>
            </IndentedHeading>
          )}
          {d.data.map(auraQuestion => mapAuraComponents(auraQuestion))}
        </>
      );
    });
  };

  return (
    <Form
      onSubmit={async e => {
        e.preventDefault();
        setIsValidating(true);
        const hasErrors = questionsData.filter(x => !x.isAnswered).length > 0;
        if (!hasErrors) {
          setLoading(true);
          await navigateToNextSection();
        } else {
          console.log(
            'validation errors:',
            questionsData.filter(x => !x.isAnswered)
          );
        }
      }}>
      <FormSection>
        <Stack>{data && mapContexts()}</Stack>
      </FormSection>

      <FormActions loading={loading} disabled={isAnswering} />

      <RouteChangeModal
        url={navigatedRoute}
        onCancelRouteChange={() => {
          setRouteChangeModalOpen(false);
        }}
        onAcceptRouteChange={() => {
          activeSection.isComplete = false;
        }}
        isOpen={routeChangeModalOpen}>
        <Heading level={3}>Section Incomplete</Heading>
        <ModalTextContainer>
          <ModalText>Are you sure you want to leave this section?</ModalText>
          <ModalText>You will lose any information you&apos;ve entered.</ModalText>
          <ModalText>To save your information, please complete all fields and click Next.</ModalText>
        </ModalTextContainer>
      </RouteChangeModal>

      <AddDoctorSnl />
    </Form>
  );
};

export default AuraRest;

const IndentedHeading = styled.div`
  border-left: 4px solid ${props => props.theme.colors.blue};
  padding-left: ${props => props.theme.gutters[12]};
  padding-top: ${props => props.theme.gutters[4]};
`;

const IndentedHeadingHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const IndentedHeadingSubHeading = styled(Text)`
  margin-top: 0;
`;

const ModalTextContainer = styled.div`
  margin: ${props => props.theme.gutters[8]};
`;

const ModalText = styled(Text)`
  margin-top: ${props => props.theme.gutters[12]};
  margin-bottom: 0;
`;
