import React, { useState, useEffect } from 'react';
import { SegmentedControl, TextInput, Text, Hint } from '@fmi/design-system';
import { hasError, hasErrorMessage } from '@src/utils/FormikHelpers';
import { IdControlNames, YesNo, IdProps } from '.';

const Id: React.FC<IdProps> = ({
  idNumber,
  idPermanentResident = '',
  idWorkOutsideRsa = '',
  formik,
  hidePermanentResidentSelection = false,
}) => {
  const [
    selectedIdPermanentResident,
    setSelectedIdPermanentResident,
  ] = useState('');

  useEffect(() => {
    if (idPermanentResident === YesNo.Yes || idPermanentResident === YesNo.No) {
      setSelectedIdPermanentResident(idPermanentResident);
    }
  }, [idPermanentResident]);

  return (
    <>
      <TextInput
        label="ID Number"
        name={IdControlNames.IdNumber}
        id={IdControlNames.IdNumber}
        value={idNumber}
        mask="999999 9999 99 9"
        onChange={formik.handleChange}
        error={hasError(formik, IdControlNames.IdNumber)}
        errorMessage={hasErrorMessage(formik, IdControlNames.IdNumber)}
        fieldType="tel"
      />

      {hidePermanentResidentSelection === false && (
        <>
          <Text>Do you currently live in South Africa permanently?</Text>
          <SegmentedControl
            defaultOption={YesNo.Yes}
            segments={[YesNo.Yes, YesNo.No]}
            name={IdControlNames.IdPermanentResident}
            id={IdControlNames.IdPermanentResident}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSelectedIdPermanentResident(event.target.value);
              formik.handleChange(event);
            }}
            value={
              idPermanentResident === YesNo.Yes ||
              idPermanentResident === YesNo.No
                ? idPermanentResident
                : undefined
            }
            error={hasError(formik, IdControlNames.IdPermanentResident)}
            errorMessage={formik.errors.idPermanentResident}
          />
        </>
      )}

      {selectedIdPermanentResident === YesNo.No && (
        <>
          <Text>Do you work outside the borders of South Africa?</Text>
          <SegmentedControl
            defaultOption={YesNo.Yes}
            segments={[YesNo.Yes, YesNo.No]}
            name={IdControlNames.IdWorkOutsideRsa}
            id={IdControlNames.IdWorkOutsideRsa}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formik.handleChange(event);
            }}
            value={
              idWorkOutsideRsa === YesNo.Yes || idWorkOutsideRsa === YesNo.No
                ? idWorkOutsideRsa
                : undefined
            }
            error={hasError(formik, IdControlNames.IdWorkOutsideRsa)}
            errorMessage={formik.errors.idWorkOutsideRsa}
          />
          <Hint icon="MdInfoOutline" iconColor="sinbad" iconSize="1.375em">
            This excludes holiday or recreational travel
          </Hint>
        </>
      )}
    </>
  );
};

export { Id };
