import React, { useState, useEffect, useRef } from 'react';
import { Text, Checkbox, ValidationMessage } from '@fmi/design-system';
import styled from 'styled-components';
import { AuraQuestion } from '@src/models';

type MultipleChoiceAnswer = {
  value: string;
};

type CheckboxGroupProps = {
  question: AuraQuestion;
  onAnswer: (answer: {
    questionType: string;
    answers: MultipleChoiceAnswer[];
  }) => void;
  error: boolean;
};

export const CheckboxGroup: React.FC<CheckboxGroupProps> = props => {
  const { question, onAnswer, error } = props;
  const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState<
    MultipleChoiceAnswer[]
  >([]);
  const [isDisabled, setIsDisabled] = useState(false);

  // prime state on first load if there are answers
  useEffect(() => {
    if (question.isAnswered) {
      const intialState = question.answer.answers.map(a => {
        return {
          value: a.value,
        };
      });
      setMultipleChoiceAnswers(intialState);
    }
  }, []);

  const getAndSet = async (value: string) => {
    setIsDisabled(true);
    return new Promise<MultipleChoiceAnswer[]>(resolve => {
      setTimeout(() => {
        const currentIndex = multipleChoiceAnswers.findIndex(
          x => x.value === value
        );
        if (currentIndex !== -1) {
          const clonedArr = [...multipleChoiceAnswers];
          clonedArr.splice(currentIndex, 1);
          setMultipleChoiceAnswers(clonedArr);
          onAnswer({
            questionType: question.type,
            answers: clonedArr,
          });
        } else {
          if (value === 'NONE_OF_ABOVE_CHOICE_CODE') {
            setMultipleChoiceAnswers([
              {
                value,
              },
            ]);
            onAnswer({
              questionType: question.type,
              answers: [
                {
                  value,
                },
              ],
            });
          } else {
            const noneIndex = multipleChoiceAnswers.findIndex(
              x => x.value === 'NONE_OF_ABOVE_CHOICE_CODE'
            );
            const clonedArr = [...multipleChoiceAnswers];
            if (noneIndex !== -1) {
              clonedArr.splice(currentIndex, 1);
            }
            setMultipleChoiceAnswers([
              ...clonedArr,
              {
                value,
              },
            ]);
            onAnswer({
              questionType: question.type,
              answers: [
                ...clonedArr,
                {
                  value,
                },
              ],
            });
          }
        }
        setIsDisabled(false);
        resolve(multipleChoiceAnswers);
      }, 1000);
    });
  };

  return (
    <div>
      <Text>{question.text}</Text>
      {question.constraints.choices.map(c => (
        <Checkbox
          key={c.value}
          label={c.text}
          value={c.value}
          disabled={isDisabled}
          onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation();
            getAndSet(e.target.value);
          }}
          checked={
            multipleChoiceAnswers.filter(x => x.value === c.value).length > 0
          }
          error={error}
        />
      ))}
      {error && <Validation showIcon>Please make a selection</Validation>}
    </div>
  );
};

const Validation = styled(ValidationMessage)`
  margin-top: ${props => props.theme.gutters[8]};
  margin-left: ${props => props.theme.gutters[12]};
  margin-right: ${props => props.theme.gutters[12]};
`;
