import React, { useState, useRef } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
  Text,
  Modal,
  Heading,
  RadioButtonGroup,
  TextInput,
  Icon,
} from '@fmi/design-system';
import { Stack } from '@src/components';
import styled from 'styled-components';
import { RequiredFile, FileItem, UploadedFile } from './Models';
import { Center } from '../Center';

export interface FileSelectModalProps {
  isOpen: boolean;
  filesList: RequiredFile[];
  onFileSelected: (upload: UploadedFile) => void;
  onModalDismiss: () => void;
}
export const FileSelectModal = ({
  isOpen,
  filesList,
  onFileSelected,
  onModalDismiss,
}: FileSelectModalProps) => {
  const [isUploadDisabled, setIsUploadDisabled] = useState(true);
  const [selected, setSelected] = useState('');
  const [userInputValue, setUserInputValue] = useState('');
  const fileInputRef = useRef(null);

  const handleUploadNowClick = () => {
    return fileInputRef ? fileInputRef.current.click() : false;
  };

  // handle file input change event (file selected)
  const handleFileInputChange = event => {
    event.preventDefault();
    setIsUploadDisabled(true);
    // callback to parent
    onFileSelected({
      requiredFile: selected,
      uploadedFileName: event.target.files[0].name,
      description: userInputValue,
      timestamp: Date.now(),
      size: event.target.files[0].size,
      type: event.target.files[0].type,
      status: 'selected',
      progress: 0,
      fileData: event.target.files[0],
      uploadedSize: null,
      cancelToken: null,
    });
  };

  // handle radio button option select
  const handleOptionSelect = e => {
    setSelected(e.target.value);
    const selection = filesList.find(item => item.name === e.target.value);
    selection && selection.allowMultiple === false
      ? setIsUploadDisabled(false)
      : setIsUploadDisabled(true);

    e.preventDefault();
  };

  // handle text entered into option grooup text input for file name
  const handleTextInput = (val: string) => {
    // store the value the user entered for the file being uploaded
    setUserInputValue(val);
    val && val.length >= 1
      ? setIsUploadDisabled(false)
      : setIsUploadDisabled(true);
  };

  // files list accordion component
  const FilesUpload = filesList.map(file => ({
    label: file.name,
    disabled: true,
    accordionContent: file.allowMultiple ? (
      <div>
        <TextInput
          label={file.inputPlaceholderText || file.name}
          placeholder="Placeholder Label or Assistive Text"
          onChange={e => handleTextInput(e.target.value)}
        />
      </div>
    ) : (
      undefined
    ),
    value: file.name,
  }));

  const handleOnModalDismiss = () => {
    setIsUploadDisabled(true);
    onModalDismiss();
  };

  return (
    <Modal isOpen={isOpen}>
      <>
        <CloseAction onClick={handleOnModalDismiss}>
          <Icon icon="MdClose" size="22px" color="white" />
        </CloseAction>
        <StyledModalContent>
          <Heading level={3}>Which document are you uploading?</Heading>
          <RadioButtonGroup
            options={FilesUpload}
            onChange={e => handleOptionSelect(e)}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={e => handleFileInputChange(e)}
          />
          <PrimaryButton
            appearance="blue"
            onClick={handleUploadNowClick}
            disabled={isUploadDisabled}>
            Upload Now
          </PrimaryButton>

          {/* Camera Upload Function Coming Soon */}
          {/* <Center>
          <CameraText color="wasabi" appearance="misc">
            <Icon icon="MdFilterCenterFocus" size="22px" />
            Use Camera To Upload
          </CameraText>
        </Center> */}
        </StyledModalContent>
      </>
    </Modal>
  );
};

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const CameraText = styled(Text)`
  margin: 0;
  margin-top: ${props => props.theme.gutters[16]};
  font-size: ${props => props.theme.fontSize[13]};
  text-align: center;
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

const CloseAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.sinbad};
  position: absolute;
  top: -12px;
  right: -8px;
  cursor: pointer;
`;
