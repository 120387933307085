export const numeric = new RegExp(/^\d+(?:\.\d{1,2})?$/);

export function displayValidateNumber(
  number: number | string
): string | undefined {
  const value = `${number}`;

  if (!numeric.test(value) && value !== '') {
    return 'Must contain only numeric characters';
  }

  return undefined;
}
