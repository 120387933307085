import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Heading, Icon } from '@fmi/design-system';
import { useBreakpoint } from '@src/hooks';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  Overlay,
  StyledWidget,
  StyledContainer,
  StyledTitleBar,
  StyledScrollContainer,
  StyledIconWrapper,
  StyledContent,
} from './StyledSecondaryNativeLayer';

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
};

const SecondaryNativeLayer: React.FC<Props> = ({
  children,
  title,
  isOpen,
  onClose,
}) => {
  const [snlRoot, setSnlRoot] = useState(undefined);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    const container = document.querySelector('#styledContainer');
    if (container !== null) {
      container.scrollTop = 0;
    }

    setSnlRoot(document.querySelector('#snl-root'));
  }, [isOpen]);

  if (snlRoot === undefined) {
    return null;
  }

  const handleGoBack = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <StyledWidget isOpen={isOpen}>
      <Overlay />
      <StyledContainer id="styledContainer">
        <StyledTitleBar>
          <StyledIconWrapper onClick={handleGoBack} id="snl-close">
            <Icon icon="MdArrowBack" color="sinbad" size="22px" />
          </StyledIconWrapper>
          <Heading
            level={breakpoint === 'mobile' ? 4 : 3}
            color="white"
            className="headingText">
            {title}
          </Heading>
        </StyledTitleBar>
        {isOpen && (
          <StyledScrollContainer>
            <Scrollbars universal>
              <StyledContent>{children}</StyledContent>
            </Scrollbars>
          </StyledScrollContainer>
        )}
      </StyledContainer>
    </StyledWidget>,
    snlRoot
  );
};

export { SecondaryNativeLayer };
