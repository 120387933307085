import React from 'react';
import { StyledStack } from './StyledStack';

type Props = {
  marginBottom?: string;
};

export const Stack: React.FC<Props> = ({ children, marginBottom }) => (
  <StyledStack marginBottom={marginBottom}>{children}</StyledStack>
);

export default Stack;
