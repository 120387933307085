import React from 'react';
import { Heading, Icon } from '@fmi/design-system';
import styled from 'styled-components';

type ReviewCardProps = {
  number: number;
  name: string;
  onClick: () => void;
};

export const ReviewCard: React.FC<ReviewCardProps> = ({
  number,
  name,
  onClick,
}) => {
  return (
    <Container onClick={onClick}>
      <Left>
        <Number>{number}</Number>
        <Name level={4} weight={500}>
          {name}
        </Name>
      </Left>
      <Right>
        <Icon icon="MdEdit" size="22px" color="#3c3c3c" />
      </Right>
    </Container>
  );
};

const Container = styled.button`
  border: 1px solid ${props => props.theme.colors.lightGrey};
  border-radius: 8px;
  padding: ${props => props.theme.gutters[24]};
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  width: 100%;
  outline: 0;
  margin-top: ${props => props.theme.gutters[12]};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;

  &:hover {
    border-color: ${props => props.theme.colors.sinbad};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Right = styled.div`
  position: absolute;
  right: 16px;
  margin-top: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${props => props.theme.colors.lightGrey};
  }
`;

const Number = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: ${props => props.theme.colors.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  font-size: ${props => props.theme.fontSize[13]};
`;

const Name = styled(Heading)`
  margin-left: ${props => props.theme.gutters[12]};
  text-align: left;
`;
