import {
  DetailsCard,
  InteractiveCard,
  PrimaryButton,
  SecondaryButton,
  Text,
  Modal,
  Heading,
} from '@fmi/design-system';
import styled from 'styled-components';

export interface LoseInfoModalGenericProps {
  isOpen: boolean;
  onAcceptLoseInfo: () => void;
  onCancelLoseInfo: () => void;
}
export const LoseInfoModalGeneric = ({
  isOpen,
  onCancelLoseInfo,
  onAcceptLoseInfo,
}: LoseInfoModalGenericProps) => {
  return (
    <Modal isOpen={isOpen}>
      <StyledModalContent>
        <Heading level={3}>Hang on a sec…</Heading>
        <Text>
          If you change your answer now, the information you&apos;ve given us
          will be deleted.
        </Text>
        <Text>Are you sure you want to change your answer?</Text>
        <PrimaryButton appearance="red" onClick={onCancelLoseInfo}>
          No, Keep It
        </PrimaryButton>
        <SecondaryButton onClick={onAcceptLoseInfo}>
          Yes, Delete It
        </SecondaryButton>
      </StyledModalContent>
    </Modal>
  );
};
const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
