/* eslint-disable no-nested-ternary */
import React from 'react';
import { Heading, Indent } from '@fmi/design-system';
import { useBreakpoint } from '@src/hooks';
import {
  StyledFormSection,
  FormSectionWrapper,
  InnerFormSection,
  SubHeading,
  SubSectionHeading,
  Divider,
  HeadingWrapper,
  DisabledOverlay,
} from './StyledFormSection';

type Props = {
  heading?: string;
  subheading?: string;
  subsectionHeading?: string;
  showDisabledOverlay?: boolean;
};

export const FormSection: React.FC<Props> = ({
  heading,
  subheading,
  subsectionHeading,
  children,
  showDisabledOverlay = false,
}) => {
  const breakpoint = useBreakpoint();

  return (
    <StyledFormSection hasHeading={!!(heading || subsectionHeading)}>
      <FormSectionWrapper>
        {showDisabledOverlay && <DisabledOverlay />}
        <HeadingWrapper reverse={!!(heading && subsectionHeading)}>
          {heading && (
            <Indent
              gutter={
                breakpoint === 'mobile'
                  ? '14px'
                  : breakpoint === 'tablet'
                  ? '22px'
                  : '29px'
              }>
              <Heading level={breakpoint === 'mobile' ? 4 : 3}>
                {heading}
              </Heading>
              {subheading && <SubHeading>{subheading}</SubHeading>}
            </Indent>
          )}
          {subsectionHeading && (
            <SubSectionHeading isReversed={!!(heading && subsectionHeading)}>
              <Heading level={4} weight={600}>
                {subsectionHeading}
              </Heading>
              <Divider />
            </SubSectionHeading>
          )}
        </HeadingWrapper>
        <InnerFormSection hasHeadings={!!(heading || subsectionHeading)}>
          {children}
        </InnerFormSection>
      </FormSectionWrapper>
    </StyledFormSection>
  );
};
