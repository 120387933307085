import getConfig from 'next/config';

export const completeApplication = async ({ applicationId }): Promise<any> => {
  const { publicRuntimeConfig } = getConfig();

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      applicationId,
    }),
  };

  await fetch(
    `${publicRuntimeConfig.host}/api/completeApplication`,
    requestOptions
  );
};
