import styled from 'styled-components';

export const StyledFormActions = styled.section`
  padding: ${props => props.theme.gutters[16]}
    ${props => props.theme.gutters[40]} ${props => props.theme.gutters[60]};
  width: 100%;

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    padding: ${props => props.theme.gutters[16]}
      ${props => props.theme.gutters[24]} ${props => props.theme.gutters[40]};
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    padding: ${props => props.theme.gutters[16]}
      ${props => props.theme.gutters[40]} ${props => props.theme.gutters[40]};
    max-width: 1160px;
    margin: 0 auto;
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_SMALL} {
    padding: ${props => props.theme.gutters[16]}
      ${props => props.theme.gutters[32]} ${props => props.theme.gutters[40]};
  }

  & + button {
    margin-top: 0;
    margin-bottom: ${props => props.theme.gutters[16]};
  }
`;
