import React, { useState, useContext, useEffect } from 'react';
import { useFetch, useProgress } from '@src/hooks';
import { PositionedLoader } from '@src/components';
import { fetchInputXml } from '@src/utils';
import { QuoteContext, AuraContext } from '@src/contexts';
import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { AuraProps } from './AuraProps';

const { publicRuntimeConfig } = getConfig();

declare let deui: any;

export const Aura: React.FC<AuraProps> = ({
  currentSection,
  onSectionComplete,
}) => {
  const { deUrl, environment } = publicRuntimeConfig.aura;
  const router = useRouter();
  const [token, loading] = useFetch<{ access_token: string }>(
    `/api/token?env=${environment}`
  );
  const { setApplicationSaving } = useProgress();
  const { quote } = useContext(QuoteContext);
  const { aura } = useContext(AuraContext);
  const [auraLoading, setAuraLoading] = useState(true);
  const [interviewStarted, setInterviewStarted] = useState(false);
  const [interviewComplete, setInterviewComplete] = useState(false);
  const showManualDuties =
    quote.lifeInsured?.showManualDutiesQuestionsOnApp ?? false;

  // fetch inputXml to pass to Aura startInterview
  const { data, error } = useSWR(
    router.query.id ? router.query.id : null,
    fetchInputXml
  );

  if (error) console.log('Error fetching inputxml');
  if (error) console.log(error);

  const start = () => {
    if (interviewStarted && !interviewComplete) return;
    setInterviewStarted(true);

    const inputXml = data;
    const nav = {
      initNavControls: initNavControls => {
        console.info('initNavControls', initNavControls);
      },
      sectionStatusChanged: sectionId => {
        if (deui.navigation.navSections[sectionId].status === 'complete') {
          setInterviewComplete(true);
          setApplicationSaving(false);
          console.log('AURA section complete 1');
          onSectionComplete(true);
        } else {
          onSectionComplete(false);
        }
      },
      sectionActivated: (activeSectionId, priorActiveSectionId) => {
        console.log('AURA section activated', activeSectionId);
        console.log('AURA previous section', priorActiveSectionId);
      },
    };
    deui.navigation = nav;
    const deuiAppConfig: any = {
      allowModificationsAfterSubmit: false,
      sectionNav: 'NONE',
      showButtons: false,
      navigation: nav,
      limitedVisibilitySections: [],
      onInterviewStarted(deResult) {
        console.log('AURA navigating to section ', currentSection);
        deui.App.navigateToSection(currentSection);
        console.log('AURA navigated to section ', currentSection);
        if (deui.navigation.navSections[currentSection].status === 'complete') {
          setInterviewComplete(true);
          onSectionComplete(true);
          console.log('AURA section complete 2');
        } else {
          onSectionComplete(false);
        }
        setAuraLoading(false);
      },
      onInterviewComplete(deResult) {
        console.log('AURA INTERVIEW COMPLETE', deResult);
        setApplicationSaving(false);
      },
      onInterviewStatusChange(deResult) {
        console.log('AURA interview status changed', deResult);
      },
      unexpectedConditionOccured(error) {
        Sentry.captureException(new Error(error.message));
        console.error(`Error message:  ${error.message}`);
        console.error(`Error:  ${error}`);
        return true;
      },
      onSaveAndExitComplete(deResult) {
        console.log('AURA onSaveAndExitComplete', deResult);
        setApplicationSaving(false);
      },
      onSaveAndExitNotComplete(deResult) {
        console.log('AURA onSaveAndExitNotComplete', deResult);
        setApplicationSaving(false);
      },
    };

    console.log(
      'aura.phaseUsedWithApplication = ',
      aura.phaseUsedWithApplication
    );

    if (aura.phaseUsedWithApplication !== '1') {
      deuiAppConfig.limitedVisibilitySections.push({
        externalSectionId: 'Financial Status',
      });
      deuiAppConfig.limitedVisibilitySections.push({
        externalSectionId: 'Travel',
      });
      deuiAppConfig.limitedVisibilitySections.push({
        externalSectionId: 'Other Long Term Insurance',
      });
      deuiAppConfig.limitedVisibilitySections.push({
        externalSectionId: 'Hobbies and Pursuits',
      });
      deuiAppConfig.limitedVisibilitySections.push({
        externalSectionId: 'Family History',
      });
      deuiAppConfig.limitedVisibilitySections.push({
        externalSectionId: 'Medical Details',
      });
      deuiAppConfig.limitedVisibilitySections.push({
        externalSectionId: 'Medical Assessment',
      });

      console.log('showManualDuties = ', showManualDuties);
      if (showManualDuties) {
        deuiAppConfig.limitedVisibilitySections.push({
          externalSectionId: 'Occupation',
        });
      }
    }

    const deuiApp = deui.App;
    deuiApp.setConfig(deuiAppConfig);

    deuiAppConfig.deUrl = deUrl;

    console.log('deuiAppConfig = ', deuiAppConfig);
    deui.App.startInterview(
      inputXml,
      'deuiContent',
      token.access_token,
      'en_US'
    );
    // deui.App.resumeInterview(
    //   router.query.id,
    //   'deuiContent',
    //   token.access_token,
    //   'en_US'
    // );
  };

  // start the interview once we have a token
  useEffect(() => {
    !loading && start();
  }, [loading]);

  // useEffect(() => {
  //   !appId return;

  // }, [appId]);

  return (
    <div>
      {(loading || auraLoading) && <PositionedLoader />}
      <div
        id="deuiContent"
        style={{ display: auraLoading ? 'none' : 'block' }}
      />
    </div>
  );
};
