/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import {
  Card,
  UploadCard,
  Text,
  PrimaryButton,
  Icon,
  Modal,
  Heading,
} from '@fmi/design-system';
import { Spacer } from '@src/components';
import styled from 'styled-components';

export function ContractFileUpload() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <StyledContainer>
      <Card>
        <Center>
          <IconCircle>
            <Icon icon="MdPlaylistAddCheck" size="22px" />
          </IconCircle>
          <Text appearance="misc">Tap To Upload</Text>
          <PrimaryButton appearance="blue">
            Copy of Active Contract
          </PrimaryButton>
          <Spacer />
          <Text color="wasabi" onClick={() => setIsOpen(!isOpen)}>
            <StyledLink>What if I can't upload it right now?</StyledLink>
          </Text>
        </Center>
        <UploadCard
          filename="document-title.docx"
          fileSize={100}
          fileProgress={60}
          status="success"
          sizeTag="KB"
          onDismiss={() => alert('Dismiss Clicked!')}>
          A simple UploadCard card
        </UploadCard>
      </Card>
      <Modal isOpen={isOpen}>
        <StyledModalContent>
          <Heading level={3}>Don't have this document right now?</Heading>
          <Text>
            Don't worry. You can come back to the application whenever you're
            ready to upload it.
          </Text>
          <PrimaryButton onClick={() => setIsOpen(!isOpen)}>
            Got It
          </PrimaryButton>
        </StyledModalContent>
      </Modal>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  align-items: center;
  position: relative;
`;

const IconCircle = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  border: 3px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: white;
  background: ${props => props.theme.colors.wasabi};
  position: absolute;
  top: -25px;
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const StyledLink = styled.span`
  cursor: pointer;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
