import styled from 'styled-components';
import { Text } from '@fmi/design-system';
import {
  SIDEBAR_DESKTOP_SMALL,
  SIDEBAR_DESKTOP_MEDIUM,
  SIDEBAR_DESKTOP_LARGE,
} from '../Layout/StyledLayout';

const StyledStepperHeader = styled.section`
  background: ${props => props.theme.colors.charcoal};
  text-align: center;
  padding: ${props => props.theme.gutters[24]}
    ${props => props.theme.gutters[16]};
  width: 100%;

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    position: fixed;
    z-index: 1;
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_SMALL} {
    width: ${SIDEBAR_DESKTOP_SMALL};
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_MEDIUM} {
    width: ${SIDEBAR_DESKTOP_MEDIUM};
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_LARGE} {
    width: ${SIDEBAR_DESKTOP_LARGE};
  }
`;

const StyledText = styled(Text)`
  margin: 0;
  font-style: italic;
`;

export { StyledStepperHeader, StyledText };
