import { PrimaryButton } from '@fmi/design-system';
import { FormActions } from '..';

type Props = {
  hasInteractiveButton: boolean;
  text: string;
  openSnl: () => void;
  hideNextButton?: boolean;
  onBack?: () => void;
  loading: boolean;
};
export const InteractiveFormAction = ({
  hasInteractiveButton,
  text,
  openSnl,
  onBack,
  loading,
}: Props) => {
  return (
    <FormActions
      hideNextButton={hasInteractiveButton}
      onBackClick={onBack}
      loading={loading}>
      {hasInteractiveButton && (
        <PrimaryButton appearance="blue" icon="MdAdd" onClick={() => openSnl()}>
          {text}
        </PrimaryButton>
      )}
    </FormActions>
  );
};
