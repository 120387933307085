import React from 'react';
import styled from 'styled-components';
import { Text, Icon } from '@fmi/design-system';

type Props = {
  pretext?: string;
  text?: string;
  onClose: () => void;
  borderColor?: 'blue' | 'red' | 'amber';
  renderText?: () => void;
};

export const Alert: React.FC<Props> = ({
  pretext,
  text,
  onClose,
  borderColor = 'blue',
  renderText,
}) => {
  return (
    <Container borderColor={borderColor}>
      <>
        {renderText ? (
          renderText()
        ) : (
          <StyledText appearance="small" color="white">
            {pretext && <PreText>{pretext} </PreText>}
            {text}
          </StyledText>
        )}
      </>
      <IconContainer type="button" onClick={onClose}>
        <Icon icon="MdClose" size="22px" />
      </IconContainer>
    </Container>
  );
};

const Container = styled.div<{ borderColor: 'red' | 'blue' | 'amber' }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.charcoal};
  padding: ${props => props.theme.gutters[16]};
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  border-left: 4px solid ${props => props.theme.colors[props.borderColor]};

  & > p {
    margin: 0;
  }
`;

const PreText = styled.span`
  font-weight: 600;
`;

const StyledText = styled(Text)`
  line-height: 22px;
`;

const IconContainer = styled.button`
  margin-left: 12px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  justify-content: flex-start;
  color: #a0a0a0;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #ffffff;
  }
`;
