import { completeApplication } from '@src/utils/completeApplication';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

enum CompleteStates {
  UpdateXml,
  SubmittingAura,
  CompletingApp,
}

export const Complete = ({ onCompleted }: { onCompleted: () => void }) => {
  const router = useRouter();

  const [state] = useState(CompleteStates.CompletingApp);

  const applicationId = router.query.id as string;

  useEffect(() => {
    async function finish() {
      await completeApplication({ applicationId });
      await onCompleted();
    }

    if (state === CompleteStates.CompletingApp) {
      finish();
    }
  }, [state]);

  return <div />;
};
