import React from 'react';
import { Heading, Text, PrimaryButton } from '@fmi/design-system';
import styled from 'styled-components';
import { FormSection, ReviewCard } from '@src/components';
import { StyledFormActions } from '@src/components/FormActions/StyledFormActions';
import { ProgressSection } from '@src/models';
import { useNavigation } from '@src/hooks';

type ValidationOverviewProps = {
  heading: string;
  subheading?: string;
  subheadingCont?: string;
  items: ProgressSection[];
  onSendToAdviserClick?: () => void;
};

export const ValidationOverview: React.FC<ValidationOverviewProps> = ({
  heading,
  subheading,
  subheadingCont,
  items,
  onSendToAdviserClick,
}) => {
  const { navigate } = useNavigation();

  /**
   * if there are subsections, navigate to the first incomplete subsection
   *
   * else navigate to the section
   * @param section Section object
   */
  const navigateToIncompleteSection = (section: ProgressSection) => {
    if (section.subsections.length > 0) {
      const firstIncomplete = section.subsections.find(x => !x.isComplete);
      navigate(firstIncomplete.name);
    } else {
      navigate(section.name);
    }
  };

  const showPlural = items.length > 1;

  return (
    <>
      <FormSection>
        <StyledHeading level={3} weight={500} color="wasabi">
          {heading}
        </StyledHeading>
        <StyledText>
          {subheadingCont
            ? `The following ${showPlural ? 'sections' : 'section'} ${
                showPlural ? 'need' : 'needs'
              } to be completed before you can ${subheadingCont}`
            : subheading}
        </StyledText>
        {items.map((section, key) => (
          <ReviewCard
            key={section.key}
            number={key + 1}
            name={section.name}
            onClick={() => {
              navigateToIncompleteSection(section);
            }}
          />
        ))}
      </FormSection>

      <Actions>
        <PrimaryButton
          appearance="red"
          onClick={() => {
            navigateToIncompleteSection(items[0]);
          }}>
          Complete now
        </PrimaryButton>
        {onSendToAdviserClick && (
          <PrimaryButton
            style={{ marginRight: '8px' }}
            appearance="charcoal"
            onClick={onSendToAdviserClick}>
            Send to adviser
          </PrimaryButton>
        )}
      </Actions>
    </>
  );
};

const StyledHeading = styled(Heading)`
  margin-top: ${props => props.theme.gutters[16]};
`;

const StyledText = styled(Text)`
  margin-bottom: ${props => props.theme.gutters[40]};
`;

const Actions = styled(StyledFormActions as any)`
  justify-content: flex-start !important;
`;
