import getConfig from 'next/config';
import axios from 'axios';
import { getFileSize } from '@src/utils';

const { publicRuntimeConfig } = getConfig();

function isFileFormatAllowed(fileType) {
  let isAllowed = true;
  switch (fileType) {
    case 'application/zip':
    case 'application/exe':
      isAllowed = false;
      break;
    default:
      break;
  }
  return isAllowed;
}
// handles the progress event and calls back to parent
export const handleUploadProgressEvent = (
  progressEvent,
  file,
  updateProgressCallback
) => {
  const progress = Math.round(
    (progressEvent.loaded / progressEvent.total) * 100
  );

  const copy = {
    ...file,
    size: getFileSize(progressEvent.total),
    uploadedSize: getFileSize(progressEvent.loaded),
    progress,
    status: progress === 100 ? 'busy' : 'uploading',
  };

  // this callback function will replace the file in the state array and update
  updateProgressCallback(copy);
};

// performs the upload
export const upload = (file, onUploadProgress) => {
  const fileData = new FormData();
  fileData.append('file', file.fileData);

  const res = axios({
    url: `${publicRuntimeConfig.host}/api/file-upload`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: fileData,
    cancelToken: file.cancelToken.token,
    onUploadProgress,
  });

  return res;
};

// every upload begins here
export const startFileUpload = (file, onProgressUpdate, onSuccess, onError) => {
  // debugger;
  if (!file) return;

  // file type check
  if (!isFileFormatAllowed(file.type)) {
    onError({
      ...file, // note that its the original file, does not contain modified progress updates
      status: 'error',
      size: getFileSize(file.size),
      error: {
        message:
          'Unfortunately this file format is not allowed. Please change the file type and try again.',
      },
    });
    // bail
    return;
  }

  // file size check
  if (file.size > 1024 * 1024 * 50) {
    onError({
      ...file, // note that its the original file, does not contain modified progress updates
      status: 'error',
      size: getFileSize(file.size),
      error: {
        message:
          'Unfortunately the file size exceeds the maximum limit of 50MB. Please reduce the file size and try again.',
      },
    });
    // bail
    return;
  }

  // begin uploading
  upload(file, event => {
    handleUploadProgressEvent(event, file, onProgressUpdate);
  })
    .then(response => {
      onSuccess({
        ...file, // note that its the original file, does not contain modified progress updates
        status: 'success',
        size: getFileSize(file.size),
        fullUri: response.data.fullUri,
      });
    })
    .catch(error => {
      if (axios.isCancel(error)) {
        console.log('is axios.cancel');
      }
      console.log(error);
      onError({
        ...file, // note that its the original file, does not contain modified progress updates
        status: 'error',
        size: getFileSize(file.size),
        error: {
          message:
            'Unfortunately your upload timed out. Please reduce the file size and try again.',
        },
      });
    });
};
