import getConfig from 'next/config';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Card,
  Heading,
  Text,
  PrimaryButton,
  SecondaryButton,
  Loader,
  Modal,
} from '@fmi/design-system';
import { Center } from '@src/components';
import styled from 'styled-components';
import { getFileSize } from '@src/utils';
import { UploadCardItem } from './UploadCard';
import { FileUploadStatus } from './Models';

// Function component
const SingleFileUpload = props => {
  const { requiredDocumentName, onFileUpload, onFileRemoved } = props;

  // Modal Controls
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  // Upload File State Controls
  const [isBusy, setIsBusy] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [hasError, setHasError] = useState(false);

  // FileInfo
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [fileSize, setFileSize] = useState('0KB');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadedSize, setUploadedSize] = useState('0KB');
  const [uploadStatus, setUploadStatus] = useState<FileUploadStatus>(
    'uploading'
  );
  const [cancelToken, setCancelToken] = useState(undefined);
  const fileInputRef = useRef(null);
  const { publicRuntimeConfig } = getConfig();

  const resetUpload = () => {
    setUploadStatus('uploading');
    setIsBusy(false);
    setIsUploading(false);
    setIsDeleteOpen(false);
    setHasError(false);
    setUploadPercentage(0);
  };

  const handleUploadProgressEvent = progressEvent => {
    // unfortunate that this must happen every time, but necessary
    setFileSize(getFileSize(progressEvent.total));
    const up = Math.round((progressEvent.loaded / progressEvent.total) * 100);
    setUploadedSize(getFileSize(progressEvent.loaded));
    setUploadPercentage(up);
    if (up === 100) {
      setUploadStatus('busy');
    }
  };

  const handleUploadErrorEvent = error => {
    if (axios.isCancel(error)) {
      console.log(error);
      resetUpload();
    } else {
      setHasError(true);
      setUploadStatus('error');
    }
    // TODO: Add some retry logic in here...?
  };

  const handleUploadSuccessEvent = response => {
    setUploadPercentage(100);
    setUploadStatus('success');
    // callback to parent with path to file on storage
    onFileUpload(response.data.fullUri);
  };

  // Effect handles file uploading
  useEffect(() => {
    if (selectedFile) {
      setIsUploading(true);
      // Upload this sucka
      if (selectedFile) {
        const fileData = new FormData();
        const { CancelToken } = axios;
        const source = CancelToken.source();
        setCancelToken(source);

        fileData.append('file', selectedFile);

        axios({
          url: `${publicRuntimeConfig.host}/api/file-upload`,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: fileData,
          cancelToken: source.token,
          onUploadProgress: progressEvent => {
            handleUploadProgressEvent(progressEvent);
          },
        })
          .then(response => {
            handleUploadSuccessEvent(response);
          })
          .catch(error => {
            handleUploadErrorEvent(error);
          });
      }
    }
  }, [selectedFile]);

  const dismissUploadItem = () => {
    if (uploadStatus === 'uploading') {
      cancelToken.cancel('User Aborted');
    }
    resetUpload();
    // Prop event from parent
    onFileRemoved();
    setTimeout(() => {
      setIsBusy(false);
      setIsUploading(false);
    }, 1000);
  };

  const handleChange = event => {
    // triggers effect watching userState selectedFile
    resetUpload();
    setSelectedFile(event.target.files[0]);
  };

  const handleClick = e => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  return (
    <>
      <Card>
        <Center>
          <TitleText appearance="misc">Tap To Upload</TitleText>
          <InstructionText color="charcoal">
            Please upload the following document:
          </InstructionText>
          {isBusy && <BusyLoader size="30px" color="red" />}
          {((!isBusy && !isUploading) || hasError) && (
            <>
              <UploadButton appearance="blue" type="file" onClick={handleClick}>
                {requiredDocumentName}
              </UploadButton>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={e => handleChange(e)}
              />
            </>
          )}

          {/* Camera Upload Function Coming Soon */}
          {/* <CameraText color="wasabi" appearance="misc">
          <Icon icon="MdFilterCenterFocus" size="22px" /> 
            Use Camera To Upload
          </CameraText> */}

          {(isUploading || hasError) && (
            <UploadCardItem
              requiredDocument={requiredDocumentName}
              filename={selectedFile.name}
              fileSize={fileSize}
              uploadedSize={uploadedSize}
              fileProgress={uploadPercentage}
              status={uploadStatus}
              onDismiss={() => setIsDeleteOpen(!isDeleteOpen)}
              onCancel={() => dismissUploadItem()}
            />
          )}

          {hasError && (
            <ErrorText appearance="small" color="red">
              Unfortunately, your upload timed out. Please reduce the file size
              and try again.
            </ErrorText>
          )}

          <StyledLink color="charcoal" onClick={() => setIsOpen(!isOpen)}>
            What if I can&apos;t upload now?
          </StyledLink>
        </Center>
      </Card>

      {/* DONT HAVE DOCUMENT MODAL */}
      <Modal isOpen={isOpen}>
        <StyledModalContent>
          <Heading level={3}>Can&apos;t upload right now?</Heading>
          <Text color="charcoal">
            Don&apos;t worry. You can complete the application and we'll be in
            touch to collect the necessary documentation.
          </Text>
          <PrimaryButton onClick={() => setIsOpen(false)}>
            Got It, Thanks!
          </PrimaryButton>
        </StyledModalContent>
      </Modal>

      {/* DELETE MODAL */}
      <Modal isOpen={isDeleteOpen}>
        <StyledModalContent>
          <Heading level={3}>Delete Confirmation</Heading>
          <Text color="charcoal">
            Are you sure you want to delete this information?
          </Text>
          <PrimaryButton
            appearance="charcoal"
            onClick={() => dismissUploadItem()}>
            Yes, Delete It
          </PrimaryButton>
          <SecondaryButton
            appearance="charcoal"
            onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
            Cancel
          </SecondaryButton>
        </StyledModalContent>
      </Modal>
    </>
  );
};

const TitleText = styled(Text)`
  margin: 0;
  text-align: center;
  font-size: ${props => props.theme.fontSize[13]};
`;

const InstructionText = styled(Text)`
  margin: 0;
  margin-top: ${props => props.theme.gutters[4]};
  text-align: center;
`;

const BusyLoader = styled(Loader)`
  margin: 0;
  margin-top: ${props => props.theme.gutters[32]};
  margin-bottom: ${props => props.theme.gutters[40]};
`;

const UploadButton = styled(PrimaryButton)`
  margin: 0;
  margin-top: ${props => props.theme.gutters[24]};
`;

const CameraText = styled(Text)`
  margin: 0;
  margin-top: ${props => props.theme.gutters[16]};
  font-size: ${props => props.theme.fontSize[13]};
  text-align: center;
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

const StyledLink = styled(Text)`
  margin: 0;
  margin-top: ${props => props.theme.gutters[32]};
  text-decoration: underline;
  cursor: pointer;
`;

const ErrorText = styled(Text)`
  text-align: center;
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export { SingleFileUpload };
