import React from 'react';
import styled from 'styled-components';
import { Loader } from '@fmi/design-system';

export const PositionedLoader = () => {
  return (
    <StyledLoader>
      <Loader />
    </StyledLoader>
  );
};

const StyledLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 275px;
`;
