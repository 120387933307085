import { stripSpacesFromString } from '@src/utils';
import { displayValidationRequired } from './validateRequired';

const formatError = `The number must be 10 digits in length and start with a zero. 
                  Please do not include an international dialling code`;
const repitionError = `The number cannot include excessive repeated numbers. 
                  Please enter a valid number.`;

type errorType = ErrorValid | ErrorInValid;
interface ErrorValid {
  valid: true;
}
interface ErrorInValid {
  valid: false;
  error: string;
}
const validate = (phoneNumber: string) => {
  let error = '';
  if (/^0/g.test(phoneNumber) === false || phoneNumber.length < 10) {
    error = formatError;
  } else if (/^0\d{9}$/g.test(phoneNumber) === false) {
    error = formatError;
  }
  return error;
};
export const validatePhoneNumber = (phoneNumber: string): errorType => {
  const errorMessage = validate(stripSpacesFromString(phoneNumber));
  if (errorMessage.length > 0) {
    return {
      valid: false,
      error: errorMessage,
    };
  }
  return {
    valid: true,
  };
};
export const displayValidatePhoneNumber = (value: string) => {
  if (!value) return undefined;
  const phoneValidation = validatePhoneNumber(value);
  return phoneValidation.valid === true ? undefined : phoneValidation.error;
};

export const displayValidateRequiredPhoneNumber = (
  value: string,
  label: string,
  msg?: string
) =>
  displayValidationRequired(value, label, msg) ||
  displayValidatePhoneNumber(value);
