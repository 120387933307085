import React from 'react';
import styled, { css } from 'styled-components';
import { theme, Icon, PrimaryButton } from '@fmi/design-system';
import { RequiredFile, FileSummaryListItem, UploadedFile } from './Models';

type Props = {
  fileList: any;
};

const UploadSummaryList: React.FC<Props> = ({ fileList }) => {
  const listItems = fileList.map(file => (
    <StyledRow key={file.name}>
      <StyledLeftCol>
        {/* <StatusIcon hasError={file.hasError} uploadCount={file.uploadCcount} /> */}
        {file.hasError && (
          <Icon icon="MdClear" color={theme.colors.red} size="22px" />
        )}
        {file.uploadedCount > 0 && file.hasError !== true && (
          <Icon icon="MdCheck" color={theme.colors.wasabi} size="22px" />
        )}
        {file.uploadedCount === 0 && !file.hasError && <Dot />}
      </StyledLeftCol>
      <StyledRightCol>
        {file.name}{' '}
        {file.uploadedCount > 0 && <span>({file.uploadedCount})</span>}
      </StyledRightCol>
    </StyledRow>
  ));

  return <StyledContainer>{listItems}</StyledContainer>;
};

const StyledContainer = styled.div`
  min-width: 295px;
  border: 1px solid ${props => props.theme.colors.grey};
  border-radius: 8px;
  padding: ${props => props.theme.gutters[12]};
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fontSize[13]};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: 1.54;
  text-transform: uppercase;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLeftCol = styled.div`
  display: flex;
  justify-content: center;
  width: 22px;
  text-align: center;
  margin-right: 4px;
`;
const StyledRightCol = styled.div``;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${props => props.theme.colors.darkGrey};
`;

export { UploadSummaryList };
