import { currencyToNumber, currencyToNumberFromString } from '@src/utils';
import { CheckWithValueReplacements } from './CheckWithValueReplacement';
import { FormikCheckWithValueReplacementProps } from './FormikCheckWithValueReplacementProps';

export function FormikCheckWithValueReplacement({
  id,
  checkBoxlabel,
  textInputLabel,
  formik,
  showBenefitReplacementToggle,
}: FormikCheckWithValueReplacementProps) {
  const hasError = () =>
    formik.touched[id] &&
    formik.touched[id].value &&
    formik.errors[id] &&
    formik.errors[id].value;
  return (
    <CheckWithValueReplacements
      checkBoxlabel={checkBoxlabel}
      textInputLabel={textInputLabel}
      id={id.toString()}
      onValueChange={val => {
        val.value = currencyToNumberFromString(val.value).toString();
        formik.setFieldValue(id, val);
      }}
      value={formik.values[id]}
      error={!!hasError()}
      errorMessage={hasError()}
      showBenefitReplacementToggle={showBenefitReplacementToggle}
    />
  );
}
