import { RadioButtonGroup } from '@fmi/design-system';
import { SelectOptions } from '@src/interfaces/FormikSubset';
import { hasErrorMessage, hasError } from '@src/utils/FormikHelpers';
import { FormikCommonInputProps } from '@src/interfaces/CommonInputProps';

export interface FormikRadioButtonGroupProps
  extends FormikCommonInputProps<boolean> {
  label: string;
  options: SelectOptions[];
}
export const FormikRadioButtonGroup = ({
  formik,
  id,
  label,
  onValueChange,
  options,
}: FormikRadioButtonGroupProps) => {
  return (
    <RadioButtonGroup
      label={label}
      id={id}
      name={id}
      onChange={e => {
        !!onValueChange && onValueChange(e.target.value);
        formik.handleChange(e);
      }}
      options={options}
      value={formik.values[id]}
      error={hasError(formik, id)}
      errorMessage={hasErrorMessage(formik, id)}
    />
  );
};
