import { FormikCommonInputProps } from '@src/interfaces/CommonInputProps';
import { AsyncSelect } from '@fmi/design-system';
import { SelectList } from '@src/models';
import {
  hasErrorSelectItem,
  hasErrorMessageSelectItem,
} from '@src/utils/FormikHelpers';

export interface FormikAsyncSelectProps
  extends FormikCommonInputProps<SelectList> {
  options: (input: string) => Promise<SelectList[]>;
  placeholder: string;
  label: string;
  isCreatable: boolean;
}

export const FormikAsyncSelect = ({
  formik,
  id,
  options,
  label,
  placeholder,
  isCreatable,
}: FormikAsyncSelectProps) => {
  const handleChange = formValue => {
    console.log(formValue);
    const c =
      formValue.action.action === 'clear'
        ? { value: '', label: '' }
        : { value: formValue.value.value, label: formValue.value.label };
    formik.setFieldValue(id, c);
  };
  return (
    <AsyncSelect
      isCreatable={isCreatable}
      placeholder={placeholder}
      prefix="MdSearch"
      label={label}
      options={options}
      onChange={formValue => handleChange(formValue)}
      value={formik.values[id].value ? formik.values[id] : ''}
      showSearchIcon
      error={hasErrorSelectItem(formik, id)}
      errorMessage={hasErrorMessageSelectItem(formik, id)}
    />
  );
};
