import { TextInput, Checkbox, BenefitReplacement } from '@fmi/design-system';
import styled from 'styled-components';
import { useState } from 'react';
import {
  CheckedWithValueReplacements,
  CheckWithValueReplacementsProps,
} from './CheckWithValueReplacementProps';

export const CheckWithValueReplacements = ({
  id,
  checkBoxlabel,
  textInputLabel,
  value,
  error,
  errorMessage,
  showBenefitReplacementToggle,
  onValueChange = () => {},
}: CheckWithValueReplacementsProps) => {
  const [val, setVal] = useState(value);

  const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal: CheckedWithValueReplacements = {
      ...val,
      checked: e.target.checked,
    };
    setVal(newVal);
    onValueChange(newVal);
  };
  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal: CheckedWithValueReplacements = {
      ...val,
      value: e.target.value,
    };

    setVal(newVal);
    onValueChange(newVal);
  };
  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target);
    console.log(
      `switch checked ${e.target.checked} with value ${e.target.value}`
    );
    const newVal: CheckedWithValueReplacements = {
      ...val,
      replacement: e.target.checked,
    };
    setVal(newVal);
    onValueChange(newVal);
  };
  return (
    <>
      <Checkbox
        id={`${id}.checked`}
        name={`${id}.checked`}
        checked={val.checked}
        onChange={onCheckChange}
        label={checkBoxlabel}>
        {val.checked && (
          <>
            <InputContainer>
              <TextInput
                label={textInputLabel}
                id={`${id}.value`}
                name={`${id}.value`}
                prefix="R"
                value={Number(val.value)}
                error={error}
                errorMessage={errorMessage}
                onChange={onTextChange}
                currency={true}
              />
            </InputContainer>
            {showBenefitReplacementToggle && (
              <BenefitReplacement
                id={`${id}.replacement`}
                name={`${id}.replacement`}
                value={`${id}`}
                onChange={onSwitchChange}
                selectedForReplacement={val.replacement}
              />
            )}
          </>
        )}
      </Checkbox>
    </>
  );
};

const InputContainer = styled.div`
  height: 90px;
`;
