import { FormikSubset } from '@src/interfaces/FormikSubset';

export function hasError<T>(formik: FormikSubset<T>, key: keyof T): boolean {
  return !!(formik.touched[key] && formik.errors[key]);
}
export function hasErrorMessage<T>(formik: FormikSubset<T>, key: keyof T) {
  return formik.touched[key] && formik.errors[key];
}
export function hasErrorSelectItem<T>(
  formik: FormikSubset<T>,
  key: keyof T
): boolean {
  return !!(
    formik.touched[key] &&
    formik.touched[key].label &&
    formik.errors[key] &&
    formik.errors[key].label
  );
}
export function hasErrorMessageSelectItem<T>(
  formik: FormikSubset<T>,
  key: keyof T
) {
  return (
    formik.touched[key] &&
    formik.touched[key].label &&
    formik.errors[key] &&
    formik.errors[key].label
  );
}
