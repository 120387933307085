import React, { useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { TextInput, Select } from '@fmi/design-system';
import {
  displayValidationRequired,
  displayValidateRequiredPhoneNumber,
  displayValidateEmailAddress
} from '@src/validators';
import { useFormik } from 'formik';
import {
  SecondaryNativeLayer,
  Form,
  Stack,
  FormActionsSnl,
} from '@src/components';
import { AuraContext, SNLContext } from '@src/contexts';
import {
  fetchAuraAddDoctor,
  getCountries,
  removeUndefined,
  fetchAuraUpdateDoctor,
  fetchAuraGetDoctors,
} from '@src/utils';
import { AuraDoctor } from '@src/models';

type AddDoctorSnlProps = {
  currentDoctor?: AuraDoctor | undefined;
};

export const AddDoctorSnl: React.FC<AddDoctorSnlProps> = ({
  currentDoctor,
}) => {
  const router = useRouter();
  const { isSnlOpen, setIsSnlOpen } = useContext(SNLContext);
  const { setDoctors } = useContext(AuraContext);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: currentDoctor ? currentDoctor.firstName : '',
      lastName: currentDoctor ? currentDoctor.lastName : '',
      facilityName: currentDoctor ? currentDoctor.facilityName : '',
      phoneNumber: currentDoctor ? currentDoctor.phoneNumber : '',
      emailAddress: currentDoctor ? currentDoctor.emailAddress : '',
      addressLine1: currentDoctor ? currentDoctor.addressLine1 : '',
      addressLine2: currentDoctor ? currentDoctor.addressLine2 : '',
      city: currentDoctor ? currentDoctor.city : '',
      stateOrProvince: currentDoctor ? currentDoctor.stateOrProvince : '',
      postalCode: currentDoctor ? currentDoctor.postalCode : '',
      country: currentDoctor ? currentDoctor.country : '',
    },
    validate: values => {
      const errors = {
        firstName: displayValidationRequired(
          values.firstName,
          'First Name',
          'Please enter a first name'
        ),
        lastName: displayValidationRequired(
          values.lastName,
          'Last Name',
          'Please enter a last name'
        ),
        facilityName: displayValidationRequired(
          values.firstName,
          'Facility Name',
          'Please enter a medical facility'
        ),
        phoneNumber: displayValidateRequiredPhoneNumber(
          values.phoneNumber,
          'Contact Number',
          'Please enter a phone number'
        ),
        emailAddress: displayValidateEmailAddress(
          values.emailAddress,
          'Email Address',
          'Please enter a valid email address'
        )
      };
      return removeUndefined(errors);
    },
    enableReinitialize: true,
    onSubmit: async values => {
      setLoading(true);
      const key = `${values.firstName.toLowerCase()}|${values.lastName.toLowerCase()}`;
      if (currentDoctor) {
        await fetchAuraUpdateDoctor(
          { ...values, key },
          router.query.id as string,
          key.replace('|', ' ')
        );
      } else {
        await fetchAuraAddDoctor(values, router.query.id as string);
      }
      const doctorsRes = await fetchAuraGetDoctors(router.query.id as string);
      setDoctors(doctorsRes);
      setIsSnlOpen(false);
      setLoading(false);
      formik.resetForm();
    },
  });

  return (
    <SecondaryNativeLayer
      title="Add Doctor Details"
      isOpen={isSnlOpen}
      onClose={() => {}}>
      <Form onSubmit={formik.handleSubmit}>
        <Stack>
          <>
            <TextInput
              label="First Name"
              id="firstName"
              onChange={formik.handleChange}
              error={!!(formik.touched.firstName && formik.errors.firstName)}
              errorMessage={formik.errors.firstName}
              value={formik.values.firstName}
            />
            <TextInput
              label="Last Name"
              id="lastName"
              onChange={formik.handleChange}
              error={!!(formik.touched.lastName && formik.errors.lastName)}
              errorMessage={formik.errors.lastName}
              value={formik.values.lastName}
            />
            <TextInput
              label="Medical Facility"
              id="facilityName"
              onChange={formik.handleChange}
              error={
                !!(formik.touched.facilityName && formik.errors.facilityName)
              }
              errorMessage={formik.errors.facilityName}
              value={formik.values.facilityName}
            />
            <TextInput
              label="Phone Number"
              id="phoneNumber"
              onChange={formik.handleChange}
              fieldType="tel"
              mask="999 999 9999"
              error={
                !!(formik.touched.phoneNumber && formik.errors.phoneNumber)
              }
              errorMessage={formik.errors.phoneNumber}
              value={formik.values.phoneNumber}
            />
            <TextInput
              label="Email Address"
              id="emailAddress"
              onChange={formik.handleChange}
              fieldType="email"
              error={!!(formik.touched.emailAddress && formik.errors.emailAddress)}
              errorMessage={formik.errors.emailAddress}
              value={formik.values.emailAddress}
            />
            <TextInput
              label="Address Line 1"
              id="addressLine1"
              onChange={formik.handleChange}
              value={formik.values.addressLine1}
            />
            <TextInput
              label="Address Line 2"
              id="addressLine2"
              onChange={formik.handleChange}
              value={formik.values.addressLine2}
            />
            <TextInput
              label="City"
              id="city"
              onChange={formik.handleChange}
              value={formik.values.city}
            />
            <TextInput
              label="Province"
              id="stateOrProvince"
              onChange={formik.handleChange}
              value={formik.values.stateOrProvince}
            />
            <TextInput
              label="ZIP Code"
              id="postalCode"
              onChange={formik.handleChange}
              value={formik.values.postalCode}
            />
            <Select
              options={getCountries().map(country => {
                return {
                  value: country.code,
                  label: country.name,
                };
              })}
              onChange={({ value }) =>
                formik.setFieldValue('country', value.value)
              }
              placeholder="Country"
              label="Country"
              isSearchable
            />
          </>
        </Stack>
        <FormActionsSnl onBack={() => setIsSnlOpen(false)} loading={loading} />
      </Form>
    </SecondaryNativeLayer>
  );
};
