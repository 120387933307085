export const validateDate = (date: Date): boolean => {
  if (!date) return false;
  const unsetDate = new Date(0);
  if (date === unsetDate) return false;
  return true;
};
export const displayValidateDate = (
  value: Date,
  label: string,
  msg?: string
) => {
  const errorMsg = msg || `${label} is invalid`;
  return validateDate(value) ? undefined : errorMsg;
};
