import styled from 'styled-components';

const SIDEBAR_DESKTOP_SMALL = '360px';
const SIDEBAR_DESKTOP_MEDIUM = '440px';
const SIDEBAR_DESKTOP_LARGE = '398px';
const HEADER_HEIGHT_DESKTOP = '99px';
const HEADER_HEIGHT_MOBILE = '80px';

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
  min-width: 320px;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
`;

const StyledLayout = styled.main`
  padding-top: ${HEADER_HEIGHT_MOBILE};
  background: ${props => props.theme.colors.white};
  z-index: 1;
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    padding-top: ${HEADER_HEIGHT_DESKTOP};
  }
`;

const Sidebar = styled.aside<{ showform: boolean }>`
  width: 100%;
  background: ${props => props.theme.colors.white};
  display: ${props => (props.showform ? 'none' : 'block')};
  padding-top: ${HEADER_HEIGHT_MOBILE};

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    padding-top: ${HEADER_HEIGHT_DESKTOP};
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    display: block;
    background: ${props => props.theme.colors.lightGrey};
    position: fixed;
    height: 100vh;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_SMALL} {
    width: ${SIDEBAR_DESKTOP_SMALL};
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_MEDIUM} {
    width: ${SIDEBAR_DESKTOP_MEDIUM};
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_LARGE} {
    width: ${SIDEBAR_DESKTOP_LARGE};
  }
`;

const StepperScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 102px;
`;

const ContentArea = styled.article<{ showform: boolean }>`
  display: ${props => (props.showform ? 'block' : 'none')};
  flex-direction: column;
  padding-top: ${HEADER_HEIGHT_MOBILE};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);

  @media ${props => props.theme.BREAKPOINTS.TABLET_DOWN} {
    width: 100%;
  }

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    padding-top: ${HEADER_HEIGHT_DESKTOP};
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    display: flex;
    overflow-y: scroll;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 100vh;
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_SMALL} {
    margin-left: ${SIDEBAR_DESKTOP_SMALL};
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_MEDIUM} {
    margin-left: ${SIDEBAR_DESKTOP_MEDIUM};
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_LARGE} {
    margin-left: ${SIDEBAR_DESKTOP_LARGE};
  }
`;

export {
  StyledLayout,
  Sidebar,
  ContentArea,
  ContentWrapper,
  StepperScrollContainer,
  PageContainer,
  SIDEBAR_DESKTOP_SMALL,
  SIDEBAR_DESKTOP_MEDIUM,
  SIDEBAR_DESKTOP_LARGE,
};
