import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { AsyncSelect } from '@fmi/design-system';

function googlePlaces(router, publicRuntimeConfig: any) {
  return async inputValue => {
    const sessionToken = router.query.id;
    const result = await fetch(
      `${publicRuntimeConfig.host}/api/googleplaces/${inputValue}/${sessionToken}`
    );
    const json = await result.json();
    return json;
  };
}

export type AddressProps = {
  label?: string;
  id?: string;
  placeholder?: string;
  value?: string;
  onAddressEntered?: (val: string) => {};
  error?: boolean;
  errorMessage?: string;
};

export const Address: React.FC<AddressProps> = ({
  label = 'Physical address',
  placeholder = 'Please enter the street address',
  id,
  value,
  onAddressEntered,
  error = false,
  errorMessage,
}) => {
  const router = useRouter();
  const { publicRuntimeConfig } = getConfig();
  const promiseOptions = googlePlaces(router, publicRuntimeConfig);
  const initialValue = {
    value,
    label: value,
  };

  function handleOnChanged(e) {
    let sendValue = '';
    if (e.value !== null && e.value.value !== null) {
      sendValue = e.value.value;
    }
    onAddressEntered(sendValue);
  }

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, '');
    return inputValue;
  };

  return (
    <AsyncSelect
      placeholder={placeholder}
      options={promiseOptions}
      label={label}
      showSearchIcon
      value={initialValue.value ? initialValue : ''}
      onChange={handleOnChanged}
      isCreatable
      error={error}
      errorMessage={errorMessage}
    />
  );
};
