import React, { useEffect, useReducer } from 'react';
import axios from 'axios';
import { FileItemCard } from './FileItemCard';
import { FileUploadStatus, FileItem, UploadedFile } from './Models';
import { startFileUpload, upload } from './FileUploadService';
import { updateFileItemInArray } from './UploadHelpers';

type Props = {
  fileToUpload: any;
  onFileUploaded: (file) => void;
  onUploadCancelled: (timestamp) => void;
};

function reducer(state, action) {
  switch (action.type) {
    case 'addNewItem':
      return [...state, action.payload];
    case 'updateStatus':
      return [...updateFileItemInArray(state, action.payload)];
    case 'updateProgress':
      return [...updateFileItemInArray(state, action.payload)];
    case 'finishUpload':
      return [...updateFileItemInArray(state, action.payload)];
    case 'cancelUpload':
      return [...state.filter(x => x.timestamp !== action.payload)];
    default:
      throw new Error();
  }
}

export const ActiveUploads: React.FC<Props> = ({
  fileToUpload,
  onFileUploaded,
  onUploadCancelled,
}) => {
  const [queue, dispatch] = useReducer(reducer, []);

  // EVENTS
  const handleProgressUpdate = file => {
    dispatch({ type: 'updateProgress', payload: file });
  };

  const handleOnCancelClick = file => {
    file.cancelToken.cancel('User Cancelled Upload');
    dispatch({ type: 'cancelUpload', payload: file.timestamp });
    onUploadCancelled(file.timestamp);
  };

  // call back to parent with uploaded file
  const handleOnUploadSuccess = completedUpload => {
    dispatch({ type: 'updateProgress', payload: completedUpload });
    onFileUploaded(completedUpload);
  };
  // call back to parent with failed file
  const handleOnUploadFailed = failedUpload => {
    // console.log('hi hihi hi');
    // console.log(JSON.stringify(failedUpload, null, 2));
    dispatch({ type: 'updateProgress', payload: failedUpload });
    onFileUploaded(failedUpload);
  };

  // METHODS
  const processUpload = file => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    const upFile = {
      ...file,
      status: 'uploading',
      cancelToken: source,
    };
    dispatch({ type: 'addNewItem', payload: upFile });
    startFileUpload(
      upFile,
      handleProgressUpdate,
      success => handleOnUploadSuccess(success),
      error => handleOnUploadFailed(error)
    );
  };

  // EFFECTS
  // monitor the fileToUpload prop to kickstart the upload
  useEffect(() => {
    if (fileToUpload) {
      processUpload(fileToUpload);
    }
  }, [fileToUpload]);

  // send the entire queue back to the mothership
  // useEffect(() => {
  //   onFileUploaded(
  //     queue.filter(x => x.status === 'success' || x.status === 'error')
  //   );
  // }, [queue]);

  return (
    <>
      {queue
        .filter(x => x.status !== 'success' && x.status !== 'error')
        .map(file => (
          <FileItemCard
            key={file.timestamp}
            file={file}
            onCancel={f => handleOnCancelClick(f)}
          />
        ))}
    </>
  );
};
