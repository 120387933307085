import React, { useState, useEffect } from 'react';
import { SegmentedControl, TextInput, Text, Heading } from '@fmi/design-system';
import { Center, DashedPanel } from '@src/components';
import { hasErrorMessage, hasError } from '@src/utils/FormikHelpers';
import { PassportControlNames, YesNo, PassportProps } from '.';

const Passport: React.FC<PassportProps> = ({
  passportNumber,
  passportPermanentResident = '',
  formik,
  hidePermanentResidentNoScreen = false,
  hidePermanentResidentSelection = false,
}) => {
  const [
    selectedPassportPermanentResident,
    setSelectedPassportPermanentResident,
  ] = useState('');

  useEffect(() => {
    if (
      passportPermanentResident === YesNo.Yes ||
      passportPermanentResident === YesNo.No
    ) {
      setSelectedPassportPermanentResident(passportPermanentResident);
    }
  }, [passportPermanentResident]);

  return (
    <>
      <TextInput
        label="Passport Number"
        name={PassportControlNames.PassportNumber}
        id={PassportControlNames.PassportNumber}
        value={passportNumber}
        onChange={formik.handleChange}
        error={hasError(formik, PassportControlNames.PassportNumber)}
        errorMessage={hasErrorMessage(
          formik,
          PassportControlNames.PassportNumber
        )}
      />
      {hidePermanentResidentSelection === false && (
        <>
          <Text>Do you currently live in South Africa permanently?</Text>
          <SegmentedControl
            defaultOption={YesNo.Yes}
            segments={[YesNo.Yes, YesNo.No]}
            name={PassportControlNames.PassportPermanentResident}
            id={PassportControlNames.PassportPermanentResident}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSelectedPassportPermanentResident(event.target.value);
              formik.handleChange(event);
            }}
            value={
              passportPermanentResident === YesNo.Yes ||
              passportPermanentResident === YesNo.No
                ? passportPermanentResident
                : undefined
            }
            error={hasError(
              formik,
              PassportControlNames.PassportPermanentResident
            )}
            errorMessage={formik.errors.passportPermanentResident}
          />
        </>
      )}
      {selectedPassportPermanentResident === YesNo.No &&
        hidePermanentResidentNoScreen === false && (
          <>
            <DashedPanel>
              <Center>
                <Heading level={3} color="red" weight={500}>
                  Unfortunately…
                </Heading>
                <Text>
                  We are unable to provide cover to Foreign Nationals who do not
                  have permanent residency in South Africa.
                </Text>
              </Center>
            </DashedPanel>
          </>
        )}
    </>
  );
};

export { Passport };
