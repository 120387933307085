import { PrimaryButton, Text, Modal, Heading } from '@fmi/design-system';
import styled from 'styled-components';
import { useState, useContext } from 'react';
import { UserContext } from '@src/contexts';
import { useProgress } from '@src/hooks';
import { ModalLoader } from '../ModalLoader';

export interface SendToAdviserModalProps {
  isOpen: boolean;
  onSendToAdviser: () => Promise<void>;
  onReviewAnswers: () => void;
}
export const SendToAdviserModal = ({
  isOpen,
  onReviewAnswers,
  onSendToAdviser,
}: SendToAdviserModalProps) => {
  const [showSubmit, setShowSubmit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const { userProgress } = useProgress();
  const { user, setUser } = useContext(UserContext);
  const complete: boolean =
    userProgress.findIndex(filter => filter.isComplete === false) < 0;

  const modalContent = (loading: boolean, sendSuccess: boolean, showSubmit: boolean) => {
    let content = <></>;
    if (loading || sendSuccess) {
      content = <ModalLoader
        loading={loading}
        loadingText="Sending application..."
        successText="Application successfully sent to adviser."
        onDismiss={() => setUser({ ...user, reviewModalOpen: false })}
      />;
    } else {
      content = showContent(showSubmit);
    }
    return content;
  };
  const showContent = (showSubmit: boolean) => {
    let content = <></>;
    if (showSubmit) {
      content = <StyledModalContent>
        <Heading level={3}>One last thing…</Heading>
        <Text>
          It is important you ensure that the supplied information is
          truthful, accurate and complete in order for us to assess your
          application and provide you with the best possible cover. Failure to
          do so may change the outcome of your application or affect your
          ability to claim at a later stage.
        </Text>
        <PrimaryButton
          appearance="red"
          onClick={() => {
            setUser({ ...user, reviewModalOpen: false });
            setShowSubmit(true);
          }}>
          Cancel
        </PrimaryButton>
        <PrimaryButton
          appearance="charcoal"
          onClick={async () => {
            setLoading(true);
            await onSendToAdviser();
            setUser({ ...user, reviewModalOpen: true });
            setLoading(false);
            setSendSuccess(true);
          }}>
          I Agree
        </PrimaryButton>
      </StyledModalContent>;
    } else {
      content = <StyledModalContent>
        <Heading level={3}>Almost done…</Heading>
        {complete && <Text>You’ve completed the full application.</Text>}
        {!complete && (
          <Text>You’ve partially completed the application.</Text>
        )}
        <Text>
          Review your answers or send the application to your adviser. They
          will submit it for processing.
        </Text>
        <PrimaryButton
          appearance="red"
          onClick={() => {
            onReviewAnswers();
            setUser({ ...user, reviewModalOpen: false });
          }}>
          Review My Answers
        </PrimaryButton>
        <PrimaryButton
          appearance="charcoal"
          onClick={() => {
            setShowSubmit(false);
          }}>
          Send To Adviser
        </PrimaryButton>
      </StyledModalContent>;
    }
    return content;
  };

  return (
    <Modal isOpen={isOpen}>
      {modalContent(loading, sendSuccess, showSubmit)}
    </Modal>
  );
};

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
