import React, { useState, useEffect } from 'react';
import { Select } from '@fmi/design-system';
import { hasError, hasErrorMessage } from '@src/utils/FormikHelpers';
import useSWR from 'swr';
import { fetchNationalities } from '@src/utils/fetchNationalities';

import { IdPassportControlNames, Passport, Id, IdPassportProps } from '.';

export const IdPassport: React.FC<IdPassportProps> = ({
  passportProps,
  idProps,
  formik,
}) => {
  const { data: nationalityOptions, error } = useSWR(
    '/api/nationalities/',
    fetchNationalities,
    {
      revalidateOnFocus: false,
    }
  );

  const selectedNationality = formik.values[IdPassportControlNames.Nationality];

  return (
    <>
      <Select
        name={IdPassportControlNames.Nationality}
        id={IdPassportControlNames.Nationality}
        options={nationalityOptions || []}
        value={
          selectedNationality && {
            value: selectedNationality,
            label: selectedNationality,
          }
        }
        placeholder="Type to reveal list of nationalities"
        label="Nationality"
        isSearchable
        isClearable={false}
        onChange={({ value }) =>
          formik.setFieldValue(
            IdPassportControlNames.Nationality.toString(),
            value ? value.label : ''
          )
        }
        error={hasError(formik, IdPassportControlNames.Nationality)}
        errorMessage={hasErrorMessage(
          formik,
          IdPassportControlNames.Nationality
        )}
      />
      {selectedNationality &&
        (selectedNationality === 'South African' ? (
          <Id
            idNumber={idProps.idNumber}
            idPermanentResident={idProps.idPermanentResident}
            idWorkOutsideRsa={idProps.idWorkOutsideRsa}
            formik={formik}
            hidePermanentResidentSelection={
              idProps.hidePermanentResidentSelection
            }
          />
        ) : (
          <Passport
            nationality={passportProps.nationality}
            passportNumber={passportProps.passportNumber}
            passportPermanentResident={passportProps.passportPermanentResident}
            hidePermanentResidentNoScreen={
              passportProps.hidePermanentResidentNoScreen
            }
            hidePermanentResidentSelection={
              passportProps.hidePermanentResidentSelection
            }
            formik={formik}
          />
        ))}
    </>
  );
};

export default IdPassport;
