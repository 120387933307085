import { SelectList } from '@src/models';
import { displayValidationRequired } from '.';

export const displayValidationSelectList = (
  val: SelectList,
  label: string,
  msg?: string
) => {
  if (val === undefined) return { label: msg };
  const c = val && displayValidationRequired(val.label, label, msg);
  if (c) {
    return { label: c };
  }
  return undefined;
};
