import { FormikSubset } from '@src/interfaces/FormikSubset';

export enum IdPassportControlNames {
  Nationality = 'nationality',
  IdPassport = 'idPassport',
}

export enum PassportControlNames {
  PassportNumber = 'passportNumber',
  PassportPermanentResident = 'passportPermanentResident',
}

export type IdPassportProps = {
  idPassport: string;
  passportProps: PassportProps;
  idProps: IdProps;
  formik: FormikSubset<any>;
};

export type PassportControlName =
  | PassportControlNames.PassportNumber
  | IdPassportControlNames.Nationality
  | PassportControlNames.PassportPermanentResident;

export type PassportProps = {
  passportNumber: string;
  nationality: string;
  passportPermanentResident?: string;
  formik: FormikSubset<any>;
  hidePermanentResidentNoScreen?: boolean;
  hidePermanentResidentSelection?: boolean;
};

export enum IdControlNames {
  IdNumber = 'idNumber',
  IdPermanentResident = 'idPermanentResident',
  IdWorkOutsideRsa = 'idWorkOutsideRsa',
}

export type IdControlName =
  | IdControlNames.IdNumber
  | IdControlNames.IdPermanentResident
  | IdControlNames.IdWorkOutsideRsa;

export type IdProps = {
  idNumber: string;
  idPermanentResident?: string;
  idWorkOutsideRsa?: string;
  formik: FormikSubset<any>;
  hidePermanentResidentSelection?: boolean;
};

export enum YesNo {
  Yes = 'yes',
  No = 'no',
}

export enum IdPassportSelection {
  Id = 'id',
  Passport = 'passport',
}
