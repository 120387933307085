import React, { useContext, useEffect, useState } from 'react';
import {
  ValidationMessage,
  Text,
  Heading,
  StandardInteractiveCard,
  InteractiveCardItem,
} from '@fmi/design-system';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { AuraContext, SNLContext } from '@src/contexts';
import { fetchAuraGetDoctors } from '@src/utils';
import { AuraQuestion } from '@src/models';
import useSWR from 'swr';

type DoctorProps = {
  question: AuraQuestion;
  onAnswer: (answer: { questionType: string; key: string }) => void;
  error: boolean;
};

export const Doctor: React.FC<DoctorProps> = props => {
  const router = useRouter();
  const { question, onAnswer, error } = props;
  const { setIsSnlOpen } = useContext(SNLContext);
  const { doctors, setDoctors } = useContext(AuraContext);
  const [selectedDoctorKey, setSelectedDoctorKey] = useState(null);

  const fetchDoctors = async () => {
    const doctorsRes = await fetchAuraGetDoctors(router.query.id as string);
    return doctorsRes;
  };

  useEffect(() => {
    // if there are no doctors in state then fetch and set
    if (doctors.length === 0) {
      fetchDoctors()
        .then(d => {
          if (d.length > 0) {
            setDoctors(d);
          }
        })
        .catch(e => console.error(e));
    }
  }, [doctors]);

  // runs each time selected doctor key changes
  useEffect(() => {
    if (selectedDoctorKey) {
      onAnswer({
        questionType: question.type,
        key: selectedDoctorKey,
      });
    }
  }, [selectedDoctorKey]);

  return (
    <div data-has-form-field-error={error}>
      <Heading level={4} style={{ marginBottom: 16 }}>
        {question.text}
      </Heading>
      <StandardInteractiveCard
        buttonText="Add doctor details"
        buttonIcon="MdAdd"
        onButtonClick={() => {
          setIsSnlOpen(true);
        }}
        cardTitle="Doctor Details">
        {doctors &&
          doctors.length > 0 &&
          doctors.map(doctor => (
            <InteractiveCardItem
              key={question.id}
              hideDeleteButton
              hideEditButton
              selectable
              isSelected={
                doctor.key === selectedDoctorKey ||
                (question.isAnswered && question.answer.key === doctor.key)
              }
              onSelect={() => {
                setSelectedDoctorKey(doctor.key);
              }}>
              <TypeText color="wasabi">
                {doctor.facilityName.toUpperCase()}
              </TypeText>
              <BodyText color="charcoal">
                <span>
                  {doctor.firstName} {doctor.lastName}
                </span>
                <br />
                {doctor.phoneNumber}
              </BodyText>
            </InteractiveCardItem>
          ))}
      </StandardInteractiveCard>
      {error && (
        <DoctorValidationMessage>
          {doctors ? 'Please select a doctor' : 'Please add doctor details'}
        </DoctorValidationMessage>
      )}
    </div>
  );
};

// styles
const TypeText = styled(Text)`
  margin: 0 0 0.25rem;
  text-transform: uppercase;
  font-size: 0.625rem;
  letter-spacing: 1px;
`;

const BodyText = styled(Text)`
  margin: 0;

  span {
    font-weight: bold;
    color: black;
  }
`;

const DoctorValidationMessage = styled(ValidationMessage)`
  margin-top: 0.5rem;
  margin-left: 1em;
`;
