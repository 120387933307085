import { currencyToNumberFromString } from '@src/utils';
import { CheckWithValue } from './CheckWithValue';
import { FormikCheckWithValueProps } from './FormikCheckWithValueProps';

export function FormikCheckWithValue({
  id,
  checkBoxlabel,
  textInputLabel,
  formik,
}: FormikCheckWithValueProps) {
  const hasError = () =>
    formik.touched[id] &&
    formik.touched[id].value &&
    formik.errors[id] &&
    formik.errors[id].value;
  return (
    <CheckWithValue
      checkBoxlabel={checkBoxlabel}
      textInputLabel={textInputLabel}
      id={id.toString()}
      onValueChange={val => {
        val.value = currencyToNumberFromString(val.value).toString();
        formik.setFieldValue(id, val);
      }}
      value={formik.values[id]}
      error={!!hasError()}
      errorMessage={hasError()}
    />
  );
}
