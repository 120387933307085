import React from 'react';
import { PrimaryButton, SecondaryButton } from '@fmi/design-system';
import { useNavigation } from '@src/hooks';
import { StyledFormActions } from './StyledFormActions';

type Props = {
  onNextClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  hideNextButton?: boolean;
  hideBackButton?: boolean;
  onBackClick?: () => void;
  loading: boolean;
  nextText?: string;
  backText?: string;
  disabled?: boolean;
};

export const FormActions: React.FC<Props> = ({
  onNextClick,
  children,
  hideNextButton = false,
  hideBackButton = true,
  disabled = false,
  onBackClick,
  loading,
  nextText = 'Next',
  backText = 'Go Back',
}) => {
  const { navigateBack } = useNavigation();

  return (
    <StyledFormActions>
      {children}
      {!hideNextButton && (
        <PrimaryButton
          disabled={disabled}
          loading={loading}
          type={onNextClick ? 'button' : 'submit'}
          onClick={onNextClick}
          name="next-button">
          {nextText}
        </PrimaryButton>
      )}
      {!hideBackButton && (
        <SecondaryButton
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            if (onBackClick) {
              e.preventDefault();
              onBackClick();
            }
            navigateBack(e);
          }}>
          {backText}
        </SecondaryButton>
      )}
    </StyledFormActions>
  );
};
