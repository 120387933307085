import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { theme, Icon, Text, Progressbar, Loader } from '@fmi/design-system';
import { Center } from '@src/components';

const UploadCardItem = props => {
  const {
    requiredDocument,
    filename,
    fileSize,
    fileProgress,
    uploadedSize,
    status,
    onDismiss,
    onCancel,
  } = props;

  const isError = status === 'error';
  const isUploading = status === 'uploading';
  const isSuccess = status === 'success';
  const isBusy = status === 'busy';

  return (
    <StyledUploadCard>
      <StyledHeaderWrapper>
        <StyledHeaderText appearance="bold">
          {requiredDocument}
        </StyledHeaderText>

        <StyledActionWrapper
          onClick={(e: React.SyntheticEvent) => {
            e.preventDefault();
            isSuccess
              ? onDismiss({ filename, fileSize, fileProgress, status })
              : onCancel({ filename, fileSize, fileProgress, status });
          }}>
          <Icon
            icon={isSuccess ? 'MdDelete' : 'MdClose'}
            size="22px"
            color={theme.colors.charcoal}
          />
        </StyledActionWrapper>
      </StyledHeaderWrapper>
      <StyledFilenameText appearance="small" color="charcoal">
        {filename}
      </StyledFilenameText>

      {isBusy && (
        <Center>
          <Loader size="30px" color="red" />
        </Center>
      )}

      {isUploading && (
        <>
          <Progressbar min={0} max={100} value={fileProgress} />
          <Text appearance="small" color="charcoal">
            Uploading: {uploadedSize} of {fileSize}{' '}
            <StyledPercentageText>({fileProgress}%)</StyledPercentageText>
          </Text>
        </>
      )}

      {/* SUCCESS */}
      {isSuccess && (
        <StyledStatusText appearance="bold" color="wasabi">
          <Icon icon="MdPlaylistAddCheck" size="22px" />
          Successful Upload <span>({fileSize})</span>
        </StyledStatusText>
      )}

      {/* FAILURE */}
      {isError && (
        <StyledStatusText appearance="bold" color="red">
          <Icon icon="MdErrorOutline" size="22px" />
          Failed Upload <span>({fileSize})</span>
        </StyledStatusText>
      )}
    </StyledUploadCard>
  );
};

const boldStyles = props => {
  return css`
    font-weight: ${theme.fontWeight.bold};
  `;
};

const StyledUploadCard = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0.125em 0 0 rgba(0, 0, 0, 0.04);
  margin-top: ${props => props.theme.gutters[32]};
  padding: ${props => props.theme.gutters[16]};
  border-radius: 0.5em;
  border: solid 0.063em ${props => props.theme.colors.lightGrey};
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledHeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
`;

const StyledHeaderText = styled(Text)`
  margin: 0;
  color: ${props => props.theme.colors.black};
`;

const StyledActionWrapper = styled.button`
  outline: 0;
  background: transparent;
  border: 0;
  margin-top: 0.25em;
  cursor: pointer;
  padding: 0;
`;

const StyledFilenameText = styled(Text)`
  margin: 0;
  margin-bottom: ${props => props.theme.gutters[16]};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledPercentageText = styled.span`
  color: ${props => props.theme.colors.darkGrey};
`;

const StyledStatusText = styled(Text)`
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: bottom;
  align-content: flex-start;

  svg {
    margin-right: 4px;
  }

  span {
    font-weight: normal;
    color: ${props => props.theme.colors.darkGrey};
    margin-left: 4px;
  }
`;

export { UploadCardItem };
