import styled from 'styled-components';

export const StyledStack = styled.div<{ marginBottom }>`
  & > p:first-of-type {
    margin-top: 0;
  }

  & > div {
    margin-bottom: ${props =>
      props.marginBottom ? props.marginBottom : props.theme.gutters[28]};

    + p {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
