import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  theme,
  Icon,
  Text,
  Progressbar,
  Loader,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Heading,
} from '@fmi/design-system';
import { Center } from '@src/components';

const FileItemCard = props => {
  const { file, onDelete, onCancel } = props;
  const {
    timestamp,
    requiredFile,
    uploadedFileName,
    size,
    progress,
    uploadedSize,
    status,
    cancelToken,
    error,
  } = file;

  const isError = status === 'error';
  const isUploading = status === 'uploading';
  const isSuccess = status === 'success';
  const isBusy = status === 'busy';

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <>
      <StyledUploadCard>
        <StyledHeaderWrapper>
          <StyledHeaderText appearance="bold">{requiredFile}</StyledHeaderText>

          <StyledActionWrapper
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              isSuccess || isError
                ? setIsDeleteModalOpen(true)
                : onCancel(file);
            }}>
            <Icon
              icon={isSuccess || isError ? 'MdDelete' : 'MdClose'}
              size="22px"
              color={theme.colors.charcoal}
            />
          </StyledActionWrapper>
        </StyledHeaderWrapper>
        <StyledFilenameText appearance="small" color="charcoal">
          {uploadedFileName}
        </StyledFilenameText>

        {isBusy && (
          <Center>
            <Loader size="30px" color="red" />
          </Center>
        )}

        {isUploading && (
          <>
            <Progressbar min={0} max={100} value={progress} />
            <Text appearance="small" color="charcoal">
              Uploading: {uploadedSize} of {size}{' '}
              <StyledPercentageText>({progress}%)</StyledPercentageText>
            </Text>
          </>
        )}

        {/* SUCCESS */}
        {isSuccess && (
          <StyledStatusText appearance="bold" color="wasabi">
            <Icon icon="MdPlaylistAddCheck" size="22px" />
            Successful Upload <span>({size})</span>
          </StyledStatusText>
        )}

        {/* FAILURE */}
        {isError && (
          <StyledStatusText appearance="bold" color="red">
            <Icon icon="MdErrorOutline" size="22px" />
            Failed Upload <span>({size})</span>
          </StyledStatusText>
        )}
      </StyledUploadCard>

      {isError && error && error.message && (
        <ErrorText appearance="small" color="red">
          {error.message}
        </ErrorText>
      )}

      {/* DELETE MODAL */}
      <Modal isOpen={isDeleteModalOpen}>
        <StyledModalContent>
          <Heading level={3}>Delete Confirmation</Heading>
          <Text color="charcoal">
            Are you sure you want to delete this information?
          </Text>
          <PrimaryButton
            appearance="charcoal"
            onClick={() => {
              setIsDeleteModalOpen(false);
              onDelete(timestamp);
            }}>
            Yes, Delete It
          </PrimaryButton>
          <SecondaryButton
            appearance="charcoal"
            onClick={() => setIsDeleteModalOpen(false)}>
            Cancel
          </SecondaryButton>
        </StyledModalContent>
      </Modal>
    </>
  );
};

const boldStyles = props => {
  return css`
    font-weight: ${theme.fontWeight.bold};
  `;
};

const StyledUploadCard = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0.125em 0 0 rgba(0, 0, 0, 0.04);
  padding: ${props => props.theme.gutters[16]};
  border-radius: 0.5em;
  border: solid 0.063em ${props => props.theme.colors.lightGrey};
  margin-bottom: ${props => props.theme.gutters[16]};
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledHeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
`;

const StyledHeaderText = styled(Text)`
  margin: 0;
  color: ${props => props.theme.colors.black};
`;

const StyledActionWrapper = styled.button`
  outline: 0;
  background: transparent;
  border: 0;
  margin-top: 0.25em;
  cursor: pointer;
  padding: 0;
`;

const StyledFilenameText = styled(Text)`
  margin: 0;
  margin-bottom: ${props => props.theme.gutters[16]};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledPercentageText = styled.span`
  color: ${props => props.theme.colors.darkGrey};
`;

const StyledStatusText = styled(Text)`
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: bottom;
  align-content: flex-start;

  svg {
    margin-right: 4px;
  }

  span {
    font-weight: normal;
    color: ${props => props.theme.colors.darkGrey};
    margin-left: 4px;
  }
`;

const ErrorText = styled(Text)`
  text-align: center;
  margin-top: -4px;
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export { FileItemCard };
