import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import {
  useNavigation,
  useProgress,
  usePreventRouteChangeIf,
  useSection,
} from '@src/hooks';
import { AuraSections, AuraSectionIDs } from '@src/models';
import { Text, Heading } from '@fmi/design-system';
import { fetchAuraQuestions, fetchAuraAnswer } from '@src/utils';
import { useRouter } from 'next/router';
import { Aura } from './Aura';
import {
  FormActions,
  PositionedLoader,
  FormSection,
  RouteChangeModal,
} from '..';

const DynamicComponentWithNoSSR = dynamic(() => import('./AuraHead'), {
  ssr: false,
});
export const AuraWrapper: React.FC<{ currentSection: AuraSections }> = ({
  currentSection,
}) => {
  const router = useRouter();
  const { setApplicationSaving } = useProgress();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const { onAuraNextClick } = useNavigation();
  const { publicRuntimeConfig } = getConfig();
  const [routeChangeModalOpen, setRouteChangeModalOpen] = useState(false);
  const [navigatedRoute, setNavigatedRoute] = useState('');
  const [auraComplete, setAuraComplete] = useState(
    !publicRuntimeConfig.useAuraJs
  );
  const { activeSection } = useSection();

  const checkIncompleteQuestions = async () => {
    console.log('checking for incomplete questions...');
    const questions = await fetchAuraQuestions(
      router.query.id as string,
      AuraSectionIDs[AuraSections[currentSection]]
    );
    return questions.filter(x => !x.isAnswered);
  };

  const nextClick = async () => {
    setNextClicked(true);
    if (auraComplete) {
      setApplicationSaving(true);
      setLoading(true);
      const incompleteQuestions = await checkIncompleteQuestions();
      if (incompleteQuestions.length > 0) {
        console.log('Incomplete questions exist', incompleteQuestions);
        nextClick();
      } else {
        console.log('No incomplete questions, navigating...');
        onAuraNextClick();
        setLoading(false);
        setApplicationSaving(false);
      }
    }
  };

  useEffect(() => {
    console.log('Aura JS Complete: ', auraComplete);
  }, [auraComplete]);

  // handle the showing route change modal
  usePreventRouteChangeIf(
    !auraComplete && activeSection.isComplete && !routeChangeModalOpen,
    url => {
      setRouteChangeModalOpen(true);
      setNavigatedRoute(url);
    }
  );

  return (
    <Container>
      <FormSection showDisabledOverlay={loading}>
        {!publicRuntimeConfig.useAuraJs && <div>Aura Disabled</div>}
        {publicRuntimeConfig.useAuraJs && (
          <>
            <DynamicComponentWithNoSSR onLoaded={() => setLoaded(true)} />
            {!loaded && <PositionedLoader />}
            {loaded && (
              <Aura
                currentSection={currentSection}
                onSectionComplete={complete => {
                  setAuraComplete(complete);
                }}
              />
            )}
          </>
        )}
      </FormSection>
      {nextClicked && !auraComplete && (
        <Error>
          <Text color="red">
            Please check your form for any fields which have not been completed.
          </Text>
        </Error>
      )}
      <FormActions loading={loading} onNextClick={nextClick} />

      <RouteChangeModal
        url={navigatedRoute}
        onCancelRouteChange={() => {
          setRouteChangeModalOpen(false);
        }}
        onAcceptRouteChange={() => {
          activeSection.isComplete = false;
        }}
        isOpen={routeChangeModalOpen}>
        <Heading level={3}>Section Incomplete</Heading>
        <ModalTextContainer>
          <ModalText>Are you sure you want to leave this section?</ModalText>
          <ModalText>
            You will lose any information you&apos;ve entered.
          </ModalText>
          <ModalText>
            To save your information, please complete all fields and click Next.
          </ModalText>
        </ModalTextContainer>
      </RouteChangeModal>
    </Container>
  );
};
const Error = styled.section`
  display: flex;
  justify-content: flex-end;
  color: ${props => props.theme.colors.red};
  padding-left: ${props => props.theme.gutters[32]};
  padding-right: ${props => props.theme.gutters[32]};

  @media ${props => props.theme.BREAKPOINTS.TABLET_DOWN} {
    justify-content: center;
  }

  @media ${props => props.theme.BREAKPOINTS.TABLET} {
    padding-left: ${props => props.theme.gutters[24]};
    padding-right: ${props => props.theme.gutters[24]};
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    padding-left: ${props => props.theme.gutters[40]};
    padding-right: ${props => props.theme.gutters[40]};
    max-width: 1160px;
    margin: 0 auto;
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_SMALL} {
    padding-left: ${props => props.theme.gutters[32]};
    padding-right: ${props => props.theme.gutters[32]};
  }
`;
const Container = styled.section`
  padding-top: 0;
  z-index: -1;
`;

const ModalTextContainer = styled.div`
  margin: ${props => props.theme.gutters[8]}; 0;
`;

const ModalText = styled(Text)`
  margin-top: ${props => props.theme.gutters[12]};
  margin-bottom: 0;
`;
