import { displayValidationRequired } from '@src/validators/validateRequired';
import { displayValidateNumber } from '@src/validators/validateNumber';
import { CheckedWithValue } from '..';

export const displayValidationCheckedWithValue = (
  value: CheckedWithValue,
  label: string
): { value: string | undefined; checked?: string | undefined } | undefined => {
  if (value.checked) {
    const check =
      displayValidationRequired(value.value, label) ||
      displayValidateNumber(value.value);
    if (check) {
      return {
        value: check,
      };
    }
  }
  return undefined;
};
