import { TextInput, Checkbox } from '@fmi/design-system';
import styled from 'styled-components';
import { useState } from 'react';
import { CheckWithValueProps, CheckedWithValue } from './CheckWithValueProps';

export const CheckWithValue = ({
  id,
  checkBoxlabel,
  textInputLabel,
  value,
  error,
  errorMessage,
  onValueChange = () => {},
}: CheckWithValueProps) => {
  const [val, setVal] = useState(value);

  const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal: CheckedWithValue = { ...val, checked: e.target.checked };
    setVal(newVal);
    onValueChange(newVal);
  };
  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal: CheckedWithValue = { ...val, value: e.target.value };

    setVal(newVal);
    onValueChange(newVal);
  };
  return (
    <>
      <Checkbox
        id={`${id}.checked`}
        name={`${id}.checked`}
        checked={val.checked}
        onChange={onCheckChange}
        label={checkBoxlabel}>
        {val.checked && (
          <InputContainer>
            <TextInput
              label={textInputLabel}
              id={`${id}.value`}
              name={`${id}.value`}
              prefix="R"
              value={Number(val.value)}
              error={error}
              errorMessage={errorMessage}
              onChange={onTextChange}
              currency={true}
            />
          </InputContainer>
        )}
      </Checkbox>
    </>
  );
};

const InputContainer = styled.div`
  height: 90px;
`;
