import React, { useState } from 'react';
import styled from 'styled-components';
import { Select, Text, TextInput, ValidationMessage } from '@fmi/design-system';
import { toTitleCase } from '@src/utils';
import { AuraQuestion } from '@src/models';
import moment from 'moment';

type DateProps = {
  question: AuraQuestion;
  onAnswer: (answer: {
    questionType: string;
    dateFormat: AvailableFormats;
    value: string;
    unknownAnswer: boolean;
  }) => void;
  error: boolean;
};

type AvailableFormats =
  | 'MONTH_YEAR'
  | 'EXACT_DATE'
  | 'MONTHS_SINCE_OCCURRENCE'
  | 'RELATIVE_AGE'
  | '';

export const Date: React.FC<DateProps> = props => {
  const { question, onAnswer, error } = props;
  const [selectedFormat, setSelectedFormat] = useState<AvailableFormats>('');

  const formatSelectLabel = (format: AvailableFormats) =>
    format === 'MONTH_YEAR'
      ? toTitleCase(format.split('_').join(' ')).replace(' ', '/')
      : toTitleCase(format.split('_').join(' '));

  const mappedFormats = question.constraints.availableDateFormats.map(
    (format: AvailableFormats) => {
      return {
        value: format,
        label: formatSelectLabel(format),
      };
    }
  );

  const formatTextInputLabel = (format: AvailableFormats) => {
    switch (format) {
      case 'MONTH_YEAR':
        return 'MM/YYYY';
      case 'EXACT_DATE':
        return 'DD/MM/YYYY';
      case 'MONTHS_SINCE_OCCURRENCE':
        return 'Months';
      case 'RELATIVE_AGE':
        return 'Age';

      default:
        return 'Date';
    }
  };

  const formatDateValue = (value: string) => {
    if (selectedFormat === 'MONTH_YEAR' || selectedFormat === 'EXACT_DATE') {
      const splitDate = value.split('/');
      const day = Number(selectedFormat === 'EXACT_DATE' ? splitDate[0] : '01');
      const month =
        Number(selectedFormat === 'EXACT_DATE' ? splitDate[1] : splitDate[0]) -
        1;
      const year = Number(
        selectedFormat === 'EXACT_DATE' ? splitDate[2] : splitDate[1]
      );

      const date = moment()
        .date(day)
        .month(month)
        .year(year);

      return date.toISOString();
    }

    return value;
  };

  const reverseFormatDateValue = (date: string) => {
    const format = question.answer.dateFormat;
    const splitByT = question.answer.value.split('T');
    const d = splitByT[0];
    const splitDate = d.split('-');
    if (format === 'MONTH_YEAR') {
      return `${splitDate[1]}/${splitDate[0]}`;
    }
    if (format === 'EXACT_DATE') {
      return `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
    }
    return date;
  };

  return (
    <div>
      <Text>{question.text}</Text>
      <UnitizedContainer>
        <Select
          options={mappedFormats}
          onChange={({ value }) => {
            setSelectedFormat(value.value);
          }}
          label="Date Format"
          value={
            question.isAnswered && {
              value: question.answer.dateFormat,
              label: formatSelectLabel(question.answer.dateFormat),
            }
          }
          error={error}
        />
        <TextInput
          label={formatTextInputLabel(selectedFormat)}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value) {
              onAnswer({
                questionType: question.type,
                dateFormat: selectedFormat,
                value: formatDateValue(e.target.value),
                unknownAnswer: false,
              });
            }
          }}
          value={
            question.isAnswered && reverseFormatDateValue(question.answer.value)
          }
          error={error}
          mask={
            // eslint-disable-next-line no-nested-ternary
            selectedFormat === 'EXACT_DATE'
              ? '99/99/9999'
              : selectedFormat === 'MONTH_YEAR'
              ? '99/9999'
              : ''
          }
        />
      </UnitizedContainer>
      {error && <Validation showIcon={false}>Please enter a value</Validation>}
    </div>
  );
};

const UnitizedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    width: 49%;
  }
`;

const Validation = styled(ValidationMessage)`
  margin-top: ${props => props.theme.gutters[8]};
  margin-left: ${props => props.theme.gutters[12]};
  margin-right: ${props => props.theme.gutters[12]};
`;
