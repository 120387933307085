import React, { useState, useEffect } from 'react';
import { Text, Select, TextInput } from '@fmi/design-system';
import styled from 'styled-components';
import { AuraQuestion } from '@src/models';
import { toTitleCase } from '@src/utils';

type UnitizedProps = {
  question: AuraQuestion;
  onAnswer: (answer: {
    questionType: string;
    selectedUnit: string;
    answerParts: string[];
    unknownAnswer: boolean;
  }) => void;
  isValidating: boolean;
};

export const Unitized: React.FC<UnitizedProps> = props => {
  const { question, onAnswer, isValidating } = props;
  const [value, setValue] = useState(0);
  const [selectedUnit, setSelectedUnit] = useState<
    | {
      label: string;
      value: string;
    }
    | undefined
  >(undefined);

  const filteredUnits = question.constraints.availableUnits.filter(
    x =>
      x.unitSymbol === 'm' ||
      x.unitSymbol === 'kg' ||
      x.unitSymbol === 'PerDay' ||
      x.unitSymbol === 'PerWeek' ||
      x.unitSymbol === 'PerMonth' ||
      x.unitSymbol === 'PerYear' ||
      x.unitSymbol === 'day' ||
      x.unitSymbol === 'week' ||
      x.unitSymbol === 'month' ||
      x.unitSymbol === 'year' ||
      x.unitSymbol === 'cholMmolL'
  );
  const mappedFilteredUnits = filteredUnits.map(unit => {
    return {
      value: unit.unitSymbol,
      label: toTitleCase(unit.label),
    };
  });

  useEffect(() => {
    if (question.isAnswered) {
      setSelectedUnit(
        mappedFilteredUnits.find(x => x.value === question.answer.selectedUnit)
      );
    } else {
      setSelectedUnit(mappedFilteredUnits[0]);
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    if (inputValue !== '' && /^-?\d*\.?\d*$/.test(inputValue)) {

      const selected = filteredUnits.find(
        x => x.unitSymbol === selectedUnit.value
      );

      let [integerPart, decimalPart] = inputValue.split(".");
      if (decimalPart && decimalPart.length > selected.decimalPrecision) {
        decimalPart = decimalPart.slice(0, selected.decimalPrecision);
        inputValue = Number(`${integerPart}.${decimalPart}`).toString();
      }

      const lowerRange = selected.validationRanges[0].lower;
      const upperRange = selected.validationRanges[0].upper;
      if (Number(inputValue) < lowerRange) {
        inputValue = lowerRange.toString();
      } else if (Number(inputValue) > upperRange) {
        inputValue = upperRange.toString();
      }

      e.target.value = inputValue;
    }
  }

  const getErrorMessage = () => {
    if (filteredUnits.length === 0) return '';

    const selected = filteredUnits.find(
      x => x.unitSymbol === selectedUnit.value
    );

    const lowerRange = selected.validationRanges[0].lower;
    const upperRange = selected.validationRanges[0].upper;
    if (value < lowerRange || value > upperRange) {
      return `The maximum allowed is ${upperRange}, please adjust your entry`;
    }
    return 'Please enter a value';
  };

  return (
    <div>
      <Text>{question.text}</Text>
      {selectedUnit && (
        <UnitizedContainer>
          <Select
            options={mappedFilteredUnits}
            value={selectedUnit}
            label="Unit(s)"
            onChange={val => setSelectedUnit(val.value)}
            isSearchable={false}
            isClearable={false}
          />
          <TextInput
            label={
              selectedUnit.value === 'm'
                ? 'Please enter meters e.g. 1.50'
                : 'Units'
            }
            fieldType="number"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(e);
              setValue(Number(e.target.value));
            }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              onAnswer({
                questionType: question.type,
                selectedUnit: selectedUnit.value,
                answerParts: [e.target.value],
                unknownAnswer: false
              })
            }
            value={question.isAnswered && question.answer.answerParts[0]}
            error={!question.isAnswered && isValidating}
            errorMessage={getErrorMessage()}
          />
        </UnitizedContainer>
      )}
    </div>
  );
};

const UnitizedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    width: 49%;
  }
`;
