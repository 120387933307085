import { User } from '@src/models';

export const validateEmailAddress = (email: string): boolean => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return false;
  }

  return true;
};
export const displayValidateEmailAddress = (
  value: string,
  label: string,
  msg?: string
) => {
  const errorMsg = msg || `${label} is invalid`;
  return validateEmailAddress(value) ? undefined : errorMsg;
};
