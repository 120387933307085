import React from 'react';
import styled from 'styled-components';

type Props = {
  id?: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};
const scrollToErrorElement = () => {
  setTimeout(() => {
    const errorElement = document.querySelector(
      '[data-has-form-field-error="true"], [data-has-form-field-error="true"] label'
    );
    if (errorElement) {
      (errorElement as any).setAttribute('tabindex', '-1'); // Adding tabindex for elements not focusable
      (errorElement as any).focus();
    }
  }, 300);
};
export const Form: React.FC<Props> = ({ children, id, onSubmit }) => {
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    onSubmit(e);
    scrollToErrorElement();
  }
  return (
    <StyledForm onSubmit={handleSubmit} noValidate id={id} autoComplete="off">
      {children}
    </StyledForm>
  );
};

export default Form;

const StyledForm = styled.form`
  padding-top: 0;
  label:focus {
    outline: none;
  }
`;
