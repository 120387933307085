import React from 'react';
import { Heading } from '@fmi/design-system';

import { StyledStepperHeader, StyledText } from './StyledStepperHeader';

export const StepperHeader = ({ text }: { text: string }) => {
  return (
    <StyledStepperHeader>
      <Heading level={3} color="white">
        Application Progress
      </Heading>

      <StyledText color="darkGrey">{text}</StyledText>
    </StyledStepperHeader>
  );
};
