import styled from 'styled-components';
import {
  SIDEBAR_DESKTOP_SMALL,
  SIDEBAR_DESKTOP_MEDIUM,
  SIDEBAR_DESKTOP_LARGE,
} from '../Layout/StyledLayout';

const StyledSectionTitle = styled.section`
  background: ${props => props.theme.colors.blue};
  text-align: center;
  z-index: 1;
  position: relative;
`;

const SectionTitleWrapper = styled.div`
  padding: ${props => props.theme.gutters[24]};

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    padding: ${props => props.theme.gutters[36]}
      ${props => props.theme.gutters[40]};
    text-align: left;
    max-width: 1160px;
    margin: 0 auto;
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_SMALL} {
    padding-left: ${props => props.theme.gutters[32]};
    padding-right: ${props => props.theme.gutters[32]};
  }
`;

const Hamburger = styled.button`
  background-image: url('/images/hamburger-bg.svg');
  background-color: transparent;
  color: ${props => props.theme.colors.white};
  width: 67px;
  height: 78px;
  display: flex;
  align-items: center;
  padding-left: ${props => props.theme.gutters[16]};
  position: absolute;
  left: 0;
  top: 0;
  outline: 0;
  border: 0;

  @media ${props => props.theme.BREAKPOINTS.TABLET} {
    padding-left: ${props => props.theme.gutters[24]};
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    display: none;
  }
`;

export { StyledSectionTitle, SectionTitleWrapper, Hamburger };
