import {
  PrimaryButton,
  SecondaryButton,
  Text,
  Modal,
  Heading,
} from '@fmi/design-system';
import styled from 'styled-components';
import { useBreakpoint, useNavigation } from '@src/hooks';

export interface RouteChangeModalProps {
  children?: any;
  isOpen: boolean;
  url: string;
  onAcceptRouteChange?: () => void;
  onCancelRouteChange: () => void;
}
export const RouteChangeModal = ({
  children,
  isOpen,
  url,
  onAcceptRouteChange,
  onCancelRouteChange,
}: RouteChangeModalProps) => {
  const breakpoint = useBreakpoint();
  const { navigate } = useNavigation();

  const routeName = url.split(/[?|/]/gm)[3];

  return (
    <Modal isOpen={isOpen}>
      <StyledModalContent>
        {!children && (
          <>
            <Heading level={3}>Hang on a sec…</Heading>
            <Text>
              Please complete all fields and click next. If you leave this
              section without completing it in full, the information you have
              entered will be lost.
            </Text>
            <Text>Are you sure you want to leave this section?</Text>
          </>
        )}
        {children && children}
        <PrimaryButton appearance="red" onClick={onCancelRouteChange}>
          No, Stay Here
        </PrimaryButton>
        <SecondaryButton
          onClick={() => {
            onAcceptRouteChange && onAcceptRouteChange();
            navigate(routeName, breakpoint !== 'desktop');
          }}>
          Yes, Leave
        </SecondaryButton>
      </StyledModalContent>
    </Modal>
  );
};
const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
