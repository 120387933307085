import React from 'react';
import { PrimaryButton, SecondaryButton } from '@fmi/design-system';
import { useBreakpoint } from '@src/hooks';
import styled from 'styled-components';

type Props = {
  onBack?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  loading: boolean;
};
const StyledSnlActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.gutters[32]};
  background: white;
  flex-direction: column-reverse;

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    flex-direction: row;
    align-items: start;
    padding: ${props => props.theme.gutters[32]} 0;
  }

  .button {
    margin-top: 0;
    margin-bottom: ${props => props.theme.gutters[16]};
  }
`;
export const FormActionsSnl: React.FC<Props> = ({
  onBack = () => {},
  loading,
}) => {
  const breakpoint = useBreakpoint();

  return (
    <StyledSnlActions>
      <SecondaryButton
        id="snl-back"
        appearance="grey"
        className="button"
        onClick={onBack}>
        {breakpoint === 'desktop' || breakpoint === 'tablet'
          ? 'Close'
          : 'Go back'}
      </SecondaryButton>
      <PrimaryButton className="button" type="submit" loading={loading}>
        Save Details
      </PrimaryButton>
    </StyledSnlActions>
  );
};
