import React, { useState, useEffect, useContext } from 'react';
import { FileItemCard } from './FileItemCard';

const FileItemsList = props => {
  const { items, onDelete } = props;

  const handleOnDeleteClick = timestamp => {
    onDelete(timestamp);
  };

  const mapped = items.map(file => (
    <FileItemCard
      key={file.timestamp}
      file={file}
      onDelete={f => handleOnDeleteClick(f)}
      onCancel={f => handleOnDeleteClick(f)}
    />
  ));

  return mapped;
};

export { FileItemsList };
