import styled from 'styled-components';
import { Text, PrimaryButton, SecondaryButton } from '@fmi/design-system';
import { Center } from '../Center';

const StyledHeader = styled.header`
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  height: 80px;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0.875rem ${props => props.theme.gutters[16]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 10;

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    height: 99px;
    z-index: 1000;
    padding-left: ${props => props.theme.gutters[24]};
    padding-right: ${props => props.theme.gutters[24]};
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    padding-left: ${props => props.theme.gutters[32]};
    padding-right: ${props => props.theme.gutters[32]};
  }
`;

const Logo = styled.img`
  width: 120px;

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    width: 160px;
  }
`;

const CTAWrapper = styled.div`
  display: flex;
`;

const CTA = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 42px;
  border-radius: 25px;
  background-color: ${props => props.theme.colors.lightGrey};
  padding: 0 ${props => props.theme.gutters[40]};
  user-select: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  margin-right: ${props => props.theme.gutters[16]};

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    height: 50px;
  }

  @media ${props => props.theme.BREAKPOINTS.MOBILE_DOWN} {
    display: none;
  }
`;

const IconWrapper = styled.div`
  color: ${props => props.theme.colors.red};
  position: relative;
  top: 1px;
  height: 22px;
  width: 22px;

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    top: 0;
  }
`;

const CTAText = styled(Text)<{ textcolor: string }>`
  font-size: ${props => props.theme.fontSize[13]};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: 1px;
  margin: 0 0 0 ${props => props.theme.gutters[8]};

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    margin: 0 0 0 ${props => props.theme.gutters[4]};
  }

  :hover {
    color: #fff;
  }
`;

const SubmitApplication = styled(SecondaryButton)<{ visible: boolean }>`
  margin: 0 ${props => props.theme.gutters[16]} 0 0;
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 300ms ease-in-out;

  @media ${props => props.theme.BREAKPOINTS.MOBILE_DOWN} {
    height: 42px;
    width: 153px;
    padding: 10px 20px;
    border: none;
    background-color: ${props => props.theme.colors.lightGrey};
  }

  & svg {
    width: 22px;
    height: 22px;
  }
`;

const LiveHelp = styled(SecondaryButton)<{ visible: boolean }>`
  margin: 0;
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 300ms ease-in-out;

  @media ${props => props.theme.BREAKPOINTS.MOBILE_DOWN} {
    padding-left: ${props => props.theme.gutters[36]};
    padding-right: ${props => props.theme.gutters[36]};
  }

  & svg {
    width: 22px;
    height: 22px;
  }
`;
const LiveHelpIconButton = styled.span<{ visible: boolean }>`
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 300ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  width: 42px;
  height: 42px;
  border-radius: 42px;
  background-color: ${props => props.theme.colors.lightGrey};
`;

const SendToClient = styled(SecondaryButton)<{ visible: boolean }>`
  margin: 0 ${props => props.theme.gutters[16]} 0 0;

  @media ${props => props.theme.BREAKPOINTS.MOBILE_DOWN} {
    height: 42px;
    width: 180px;
    border: none;
    background-color: ${props => props.theme.colors.lightGrey};
  }

  & svg {
    width: 22px;
    height: 22px;
  }
`;

const SendButton = styled(PrimaryButton)`
  width: 295px;
`;
const CancelButton = styled(SecondaryButton)`
  width: 295px;
`;

const StyledContent = styled.div`
  margin-top: ${props => props.theme.gutters[28]};
  margin-bottom: ${props => props.theme.gutters[40]};
`;

const NotesWrapper = styled.div`
  margin-top: ${props => props.theme.gutters[40]};
`;

const ModalWrapper = styled.div`
  width: 464px;

  @media ${props => props.theme.BREAKPOINTS.MOBILE_DOWN} {
    width: 100%;
  }
`;

const CenteredText = styled(Center)`
  text-align: center;
`;

export {
  StyledHeader,
  Logo,
  CTAWrapper,
  CTA,
  IconWrapper,
  CTAText,
  LiveHelp,
  LiveHelpIconButton,
  SendToClient,
  SendButton,
  CancelButton,
  StyledContent,
  NotesWrapper,
  ModalWrapper,
  SubmitApplication,
  CenteredText,
};
