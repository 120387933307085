import React, { useContext, useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { createGlobalStyle } from 'styled-components';
import { theme, Stepper } from '@fmi/design-system';
import { Scrollbars } from 'react-custom-scrollbars';
import SimpleBar from 'simplebar-react';
import { ProgressSection } from '@src/models';
import { Header, Footer, SectionTitle, StepperHeader } from '@src/components';
import {
  useBreakpoint,
  useSection,
  useNavigation,
  useProgress,
} from '@src/hooks';
import { ProgressContext, UserContext } from '@src/contexts';
import {
  Sidebar,
  ContentArea,
  ContentWrapper,
  PageContainer,
  StepperScrollContainer,
} from './StyledLayout';

type Props = {
  title: string;
};

const GlobalStyle = createGlobalStyle`
  body {
    background: ${theme.colors.white};
    font-family: 'Roboto', sans-serif;

    @media ${theme.BREAKPOINTS.DESKTOP_UP} {
      overflow: hidden;
    }
  }
`;

const Layout: React.FC<Props> = ({ children, title }) => {
  const router = useRouter();
  const { showform } = router.query;
  const breakpoint = useBreakpoint();
  const { activeSection, activeSubSection } = useSection();
  const { userProgress, saving } = useProgress();
  const { navigate } = useNavigation();

  function getStepText() {
    const completeSteps = userProgress.filter(i => i.isComplete).length;

    if (completeSteps === 0) {
      return 'Let’s get started:';
    }
    if (completeSteps === userProgress.length) {
      return 'All steps complete!';
    }

    return `${completeSteps} of ${userProgress.length} sections complete:`;
  }

  function renderStepperAndFooter() {
    const inner = (
      <>
        <Stepper
          disableNavigation={saving}
          items={userProgress}
          activeSection={activeSection && activeSection.key}
          activeSubSection={activeSubSection && activeSubSection.key}
          onSectionClick={(section: ProgressSection) => {
            // if there are subsections
            if (section.subsections.length > 0) {
              // navigate to the first incomplete subsection if there are any
              const firstIncomplete = section.subsections.find(
                x => !x.isComplete
              );
              if (firstIncomplete) {
                navigate(firstIncomplete.name, breakpoint !== 'desktop');
              }
              // else navigate to the first section
              else {
                navigate(section.subsections[0].name, breakpoint !== 'desktop');
              }
            }
            // there are no subsections
            else {
              navigate(section.name, breakpoint !== 'desktop');
            }
          }}
          onSubSectionClick={(section: ProgressSection) =>
            navigate(section.name, breakpoint !== 'desktop')
          }
        />
        <Footer />
      </>
    );

    if (breakpoint === 'desktop') {
      return (
        <StepperScrollContainer>
          <Scrollbars universal>{inner}</Scrollbars>
        </StepperScrollContainer>
      );
    }

    return inner;
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <GlobalStyle />

      <PageContainer>
        <ContentWrapper>
          <Header />
          <Sidebar showform={showform === 'true'}>
            <StepperHeader text={getStepText()} />
            {renderStepperAndFooter()}
          </Sidebar>
          <ContentArea showform={showform === 'true'}>
            <SectionTitle
              title={title}
              onHamburgerClick={() => navigate(activeSection.name, false)}
            />
            {breakpoint === 'desktop' && (
              <SimpleBar>
                <>{children}</>
              </SimpleBar>
            )}
            {breakpoint !== 'desktop' && <>{children}</>}
          </ContentArea>
        </ContentWrapper>

        {breakpoint !== 'desktop' && showform === 'true' && <Footer />}
      </PageContainer>
    </>
  );
};

export { Layout };
