import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  items: string[];
};

export const SummaryList = ({ items }: Props) => {
  const listItems = items.map(item => <li key={item}>{item}</li>);

  return <StyledList>{listItems}</StyledList>;
};

const styledDot = () => css`
  &:before {
    content: '';
    width: 0.188em;
    height: 0.188em;
    border-radius: 50%;
    background: red;
    display: inline-block;
    margin: 0 0 0 0.5em;
    top: -0.188em;
    position: relative;
  }
`;

const StyledList = styled.ul`
  list-style: none;
  font-size: ${props => props.theme.fontSize[13]};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: 1.54;
  text-transform: uppercase;
  color: ${props => props.theme.colors.black};
  width: 100%;
  border: 1px solid ${props => props.theme.colors.grey};
  border-radius: 8px;
  padding-left: ${props => props.theme.gutters[32]};
  padding-top: ${props => props.theme.gutters[12]};
  padding-bottom: ${props => props.theme.gutters[12]};

  li {
    padding-bottom: ${props => props.theme.gutters[8]};
    padding-right: ${props => props.theme.gutters[32]};

    &:before {
      content: '';
      width: 0.3em;
      height: 0.3em;
      border-radius: 50%;
      background: ${props => props.theme.colors.darkGrey};
      display: inline-block;
      top: -0.15em;
      left: -1.5em;
      position: relative;
    }
  }

  li:nth-last-child(1) {
    padding-bottom: 0;
  }
`;