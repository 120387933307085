import styled from 'styled-components';
import { Indent, Text } from '@fmi/design-system';

const StyledFormSection = styled.section<{ hasHeading: boolean }>`
  padding-bottom: ${props => props.theme.gutters[8]};
  padding-top: ${props => (props.hasHeading ? props.theme.gutters[40] : 0)};

  @media ${props => props.theme.BREAKPOINTS.TABLET} {
    padding-top: ${props => props.theme.gutters[24]};
    padding-left: ${props => props.theme.gutters[24]};
    padding-right: ${props => props.theme.gutters[24]};
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    padding-top: ${props => props.theme.gutters[40]};
    padding-left: ${props => props.theme.gutters[40]};
    padding-right: ${props => props.theme.gutters[40]};
    max-width: 1160px;
    margin: 0 auto;
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_SMALL} {
    padding-left: ${props => props.theme.gutters[32]};
    padding-right: ${props => props.theme.gutters[32]};
  }
`;

const FormSectionWrapper = styled.div`
  position: relative;
  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    border: 1px solid ${props => props.theme.colors.lightGrey};
    padding: ${props => props.theme.gutters[28]} 0;
    border-radius: 8px;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06);
  }
`;

const InnerFormSection = styled.div<{ hasHeadings: boolean }>`
  padding-top: ${props => (props.hasHeadings ? props.theme.gutters[24] : 0)};
  padding-left: ${props => props.theme.gutters[16]};
  padding-right: ${props => props.theme.gutters[16]};
  padding-bottom: 0;

  @media ${props => props.theme.BREAKPOINTS.TABLET} {
    padding-left: ${props => props.theme.gutters[24]};
    padding-right: ${props => props.theme.gutters[24]};
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    padding-left: ${props => props.theme.gutters[32]};
    padding-right: ${props => props.theme.gutters[32]};
  }
`;

const SubHeading = styled(Text)`
  margin-bottom: 0;
`;

const SubSectionHeading = styled.span<{ isReversed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: ${props => (props.isReversed ? props.theme.gutters[32] : '0')};

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    align-items: flex-start;
    padding: ${props => props.theme.gutters[12]}
      ${props => props.theme.gutters[24]} 0;
    margin-bottom: ${props =>
      props.isReversed ? props.theme.gutters[32] : '0'};
  }

  @media ${props => props.theme.BREAKPOINTS.DESKTOP_UP} {
    align-items: flex-start;
    padding: ${props => props.theme.gutters[12]}
      ${props => props.theme.gutters[32]} 0;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 5rem;
  background-color: ${props => props.theme.colors.scandal};
  margin-top: ${props => props.theme.gutters[16]};

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    width: 100%;
  }
`;

const HeadingWrapper = styled.div<{ reverse: boolean }>`
  display: flex;
  flex-direction: ${props => (props.reverse ? 'column-reverse' : 'column')};
`;

const DisabledOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  top: 0;
  &:hover {
    cursor: not-allowed;
  }

  @media ${props => props.theme.BREAKPOINTS.TABLET_UP} {
    top: -${props => props.theme.gutters[28]} 0;
  }
`;

export {
  StyledFormSection,
  FormSectionWrapper,
  InnerFormSection,
  SubHeading,
  SubSectionHeading,
  Divider,
  HeadingWrapper,
  DisabledOverlay,
};
